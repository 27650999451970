import { API } from 'api'
import {
    respondToInvitationWithID,
    respondToInvitationWithUUID,
} from 'api/utils/interfaces'

export default function respondToInvitation({
    uuid,
    id,
    accept,
}: respondToInvitationWithUUID | respondToInvitationWithID): Promise<any> {
    return API.put(`invitations/${uuid || id}/`, { accept })
}
