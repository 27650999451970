import { useEffect } from 'react'

import LoadingStatus from 'state/enums/LoadingStatus'
import { loadVendor } from 'state/vendor/actions'

import { UseVendorInterface } from './types/useVendor.types'
import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'

const useVendor = () => {
    const dispatch = useAppDispatch()
    const status = useAppSelector((state) => state.vendor.status)
    const vendor = useAppSelector((state) => state.vendor.data)

    useEffect(() => {
        if (status === LoadingStatus.IDLE) {
            dispatch(loadVendor())
        }
    }, [status, dispatch])

    return { vendor, status } as UseVendorInterface
}

export default useVendor
