/* eslint-disable max-len */
import React from 'react'
import { Dropdown } from 'react-bootstrap'

import { KebabWrapper } from './KebabButton.styled'

interface KebabButtonProps {
    onEdit?: () => void
    onDelete?: () => void
    onDeactivate?: () => void
}

export default function KebabButton(defaultProps: KebabButtonProps) {
    const { onEdit, onDelete, onDeactivate } = defaultProps

    return (
        <KebabWrapper>
            <Dropdown.Toggle id="kebab-ellipsis">
                <i className="fas fa-ellipsis-vertical" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {onEdit && (
                    <Dropdown.Item onClick={onEdit}>
                        <i className="fa-solid fa-pen-to-square edit" /> Edit
                    </Dropdown.Item>
                )}
                {onDelete && (
                    <Dropdown.Item onClick={onDelete}>
                        <i className="fa-solid fa-trash-can delete" /> Delete
                    </Dropdown.Item>
                )}
                {onDeactivate && (
                    <Dropdown.Item onClick={onDeactivate}>
                        <i className="fa-solid fa-circle-xmark delete" />{' '}
                        Deactivate
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </KebabWrapper>
    )
}
