import React from 'react'
import { Button } from 'react-bootstrap'

import { PageHeadWrapper } from './PageHeader.styled'

export default function PageHeader(defaultProps: {
    onButtonClicked?: () => void
}) {
    const { onButtonClicked } = defaultProps

    return (
        <PageHeadWrapper>
            {onButtonClicked && (
                <Button className="btn-lg" onClick={() => onButtonClicked()}>
                    + New
                </Button>
            )}
        </PageHeadWrapper>
    )
}
