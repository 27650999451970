import React from 'react'
import { FormLabel } from 'react-bootstrap'

import { PartnerType } from 'apps/vendor/components/PartnerList'
import { SelectDropdown } from 'apps/vendor/components/Selects'
import { MarginStructureType } from 'apps/vendor/interfaces/subscriptions'

interface MarginStructureContainerProps {
    marginStructureList: MarginStructureType[]
    selectedMarginStructure: MarginStructureType | null
    handleSetMarginStructure: (marginStructureId: string) => void
    usePartnerArticles: boolean
    partnerOwnedLicense: boolean
    selectedPartner?: PartnerType | null
}

export default function MarginStructureContainer(
    defaultProps: MarginStructureContainerProps,
) {
    const {
        marginStructureList,
        selectedMarginStructure,
        handleSetMarginStructure,
        partnerOwnedLicense,
        usePartnerArticles,
        selectedPartner,
    } = defaultProps

    if (partnerOwnedLicense) {
        return null
    }

    const getSelectOptions = () => {
        let availableMarginStructures = marginStructureList

        if (usePartnerArticles) {
            availableMarginStructures = availableMarginStructures.filter(
                (marginStructure) =>
                    marginStructure.number_of_sales_tiers !== 1,
            )
        }

        return availableMarginStructures.map((marginStructure) => ({
            value: marginStructure.id,
            label: marginStructure.name,
        }))
    }

    const renderDropdown = () => {
        const options = getSelectOptions()

        const value = selectedMarginStructure
            ? {
                  value: selectedMarginStructure.id,
                  label: selectedMarginStructure.name,
              }
            : null

        const missingAssetMessage = usePartnerArticles
            ? 'No margin structures available from this partner.'
            : 'Create a margin structure before adding a license.'

        return (
            <SelectDropdown
                options={options}
                onChange={handleSetMarginStructure}
                value={value}
                placeholder={
                    marginStructureList.length > 0
                        ? 'Choose margin structure'
                        : missingAssetMessage
                }
                isSearchable
                isDisabled={
                    marginStructureList.length === 0 ||
                    (usePartnerArticles && !selectedPartner)
                }
            />
        )
    }

    return (
        <>
            <FormLabel>Margin Structure</FormLabel>
            {renderDropdown()}
        </>
    )
}
