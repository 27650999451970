import { Container as BContainer, Form, InputGroup } from 'react-bootstrap'

import styled from 'styled-components'
import { primary } from 'utils/colors'

export const Container = styled(BContainer)`
    max-width: 600px;
    margin-left: 0%;

    .stripe_button {
        width: 16px;
    }
`

export const EditButton = styled(InputGroup.Text)`
    color: ${primary.darkGray};
    cursor: pointer;
`

export const StripeEditableField = styled(Form.Control)`
    color: ${primary.darkGray};
`

export const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;

    > p {
        margin-bottom: 8px;
        color: ${primary.darkBlue};

        > span {
            color: ${primary.gray};
        }
    }
`

export const SettingsFormGroup = styled(Form.Group)`
    margin-bottom: 2rem;

    .form-control:not(:last-child) {
        margin-bottom: 8px;
    }

    #submit-button {
        margin-top: 0.8rem;
    }
`
