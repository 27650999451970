import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { respondToInvitation } from 'api/invitations'
import useToast from 'hooks/useToast'
import { tokenFormats } from 'routes/utils'

export default function PartnerAcceptPage() {
    const [accepted, setAccepted] = useState(false)
    const { invitationID } = useParams()
    const { errorToast } = useToast()

    useEffect(() => {
        if (invitationID && !invitationID?.match(tokenFormats.UUID)) {
            errorToast('Your token is invalid, please try again')
        } else {
            respondToInvitation({
                uuid: invitationID as tokenFormats.UUID,
                accept: true,
            }).then(() => setAccepted(true))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (accepted) {
        return <h1>Invitation successfully accepted</h1>
    }

    return (
        <h1>
            Invitation could not be accepted, please contact sharlic for more
            help.
        </h1>
    )
}
