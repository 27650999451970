import { Form } from 'react-bootstrap'

import styled from 'styled-components'
import { primary } from 'utils/colors'

export const EditableTextButtonWrapper = styled.span`
    border: none;
    background-color: transparent;
    color: ${primary.blue};
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    :hover {
        color: ${primary.lightBlue};
        text-decoration: underline;
        cursor: pointer;
    }
    :active {
        color: ${primary.darkBlue};
    }
`

export const EditableTextButtonInput = styled(Form.Control)`
    width: 150px;
    height: 24px;
`
