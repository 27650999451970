import React, { useEffect, useState } from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'

import { ErrorBadge } from 'apps/shared/components/Badges'
import { SharlicLogo } from 'apps/shared/components/Logo'
import { ErrorMessage } from 'apps/shared/shared.styled'
import useAppDispatch from 'hooks/useAppDispatch'
import { paramInWhitelist } from 'routes/utils'
import { loginUser } from 'state/user/actions'
import isEmail from 'validator/lib/isEmail'

import { Banner, LoginForm, ShowPassButton } from './Login.styled'

export default function LoginPage() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [nextParam, setNextParam] = useState('')
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [invalidCredentials, setInvalidCredentials] = useState(false)
    const [allFieldsDirty, setAllFieldsDirty] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [submitAttempted, setSubmitAttempted] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        const queryParam = location.search
        const params = queryParam.split('=')
        if (params[0] === '?next') {
            const matcher = paramInWhitelist(params[1])
            if (matcher) {
                setNextParam(params[1])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isEmail(email)) {
            setInvalidEmail(false)
        } else {
            setInvalidEmail(true)
        }
    }, [email])

    useEffect(() => {
        setAllFieldsDirty(email !== '' && password !== '')
        setInvalidCredentials(false)
    }, [email, password])

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setSubmitAttempted(true)

        const params = {
            email,
            password,
        }

        if (invalidEmail) {
            return
        }

        dispatch(loginUser(params)).then((data) => {
            if (data.type !== 'user/loginUser/fulfilled') {
                setInvalidCredentials(true)

                return
            }

            if (nextParam !== '') {
                navigate(`/${nextParam}`)
            }
            navigate(0)
        })
    }

    const toggleShowPassword = () => {
        const passwordField = document.querySelector('#formPassword')

        const type =
            passwordField?.getAttribute('type') === 'password'
                ? 'test'
                : 'password'

        passwordField?.setAttribute('type', type)
        setShowPassword(!showPassword)
    }

    return (
        <LoginForm>
            {/* eslint-disable-next-line max-len */}
            <Container>
                <div className="login-component">
                    <Banner>
                        <SharlicLogo />
                        <Container>
                            <h2>Log in to your account</h2>
                        </Container>
                    </Banner>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formEmail" className="mt-3">
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        {invalidEmail && submitAttempted && (
                            <ErrorMessage>Invalid email address</ErrorMessage>
                        )}
                        <Form.Group controlId="formPassword" className="mt-3">
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <ShowPassButton
                                type="button"
                                className={
                                    !showPassword
                                        ? 'far fa-eye'
                                        : 'far fa-eye-slash'
                                }
                                onClick={() => toggleShowPassword()}
                            />
                        </Form.Group>
                        {invalidCredentials &&
                            allFieldsDirty &&
                            ErrorBadge('Incorrect email or password.')}
                        <Form.Group id="submit-and-recover">
                            <a href="recover">Forgot password?</a>
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={
                                    !allFieldsDirty ||
                                    (invalidEmail && submitAttempted) ||
                                    (invalidCredentials && invalidEmail)
                                }
                            >
                                Log in
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            </Container>
        </LoginForm>
    )
}
