import { combineReducers } from 'redux'

import user from './user'
import vendor from './vendor'

const reducer = combineReducers({
    user,
    vendor,
})

export default reducer
