import styled from 'styled-components'
import { primary } from 'utils/colors'

export const EditButtonWrapper = styled.div`
    cursor: pointer;
    color: ${primary.blue};

    :hover {
        color: ${primary.lightBlue};
    }

    :active {
        color: ${primary.darkBlue};
    }
`
