import React from 'react'
import { Form } from 'react-bootstrap'

import { ArrowButtons, NumberInputWrapper } from './NumberInput.styled'

type ConfigType = {
    min?: number
    max?: number
    allowDecimal?: {
        isEnabled?: boolean
        maxDecimalPlaces?: number
    }
    allowLeadingZero?: boolean
    allowEmpty?: boolean
    allowArrowKeyInput?: boolean
}

type NumberInputType = {
    value: string
    onChange: (value: string) => void
    placeholder?: string
    disabled?: boolean
    config?: ConfigType
    id?: string
    incrementButton?: boolean
    decrementButton?: boolean
}

const configDefault: ConfigType = {
    allowDecimal: {
        isEnabled: true,
        maxDecimalPlaces: 2,
    },
    allowLeadingZero: true,
    allowEmpty: true,
    allowArrowKeyInput: true,
}

export default function NumberInput(defaultProps: NumberInputType) {
    const {
        value,
        onChange,
        placeholder,
        disabled,
        config,
        id,
        incrementButton,
        decrementButton,
    } = defaultProps

    const { allowDecimal: { isEnabled = true, maxDecimalPlaces = 2 } = {} } =
        config || {}

    const validatedMaxDecimalPlaces =
        typeof maxDecimalPlaces === 'number' && maxDecimalPlaces >= 0
            ? maxDecimalPlaces
            : 2

    const componentConfig = {
        ...configDefault,
        ...config,
        allowDecimal: {
            isEnabled,
            maxDecimalPlaces: validatedMaxDecimalPlaces,
        },
    }

    function isWithinRange(inputValue: number) {
        return (
            (componentConfig.min === undefined ||
                inputValue >= componentConfig.min) &&
            (componentConfig.max === undefined ||
                inputValue <= componentConfig.max)
        )
    }

    const parseValue = (input: string) => {
        let parsedValue = input
        const regex = /^-?\d+(\.\d*)?$/

        if (!regex.test(parsedValue) && parsedValue !== '') {
            return null
        }

        if (!isWithinRange(Number(parsedValue))) {
            return null
        }

        const numberOfDecimalDigits = parsedValue.split('.')[1]?.length || 0

        if (
            componentConfig.allowDecimal.isEnabled &&
            componentConfig.allowDecimal.maxDecimalPlaces
        ) {
            if (
                numberOfDecimalDigits >
                componentConfig.allowDecimal.maxDecimalPlaces
            ) {
                return null
            }
        } else if (input.includes('.')) {
            return null
        }

        if (!componentConfig.allowEmpty && parsedValue === '') {
            parsedValue = '0'
        }

        if (!componentConfig.allowLeadingZero) {
            parsedValue = parsedValue.replace(/^0+(\d+)$/, '$1')
        }

        return parsedValue
    }

    const validateInput = (input: string) => {
        const parsedValue = parseValue(input)

        if (parsedValue === null) {
            return null
        }

        return onChange(parsedValue)
    }

    const increment = (amount: number) => {
        const newValue = (parseInt(value, 10) || 0) + amount

        return validateInput(newValue.toString())
    }

    const handleKeyPress = (e: KeyboardEvent) => {
        switch (e.key) {
            case 'ArrowUp':
                e.preventDefault()
                increment(1)
                break
            case 'ArrowDown':
                e.preventDefault()
                increment(-1)
                break
            default:
                break
        }
    }

    const renderNumberInput = () => {
        return (
            <Form.Control
                id={id}
                value={value || ''}
                placeholder={placeholder}
                type="text"
                onChange={(e: any) => validateInput(e.target.value)}
                onKeyDown={(e: any) => {
                    if (componentConfig.allowArrowKeyInput) {
                        handleKeyPress(e)
                    }
                }}
                disabled={disabled}
            />
        )
    }

    const renderIncrementButtons = () => {
        return (
            <ArrowButtons>
                {incrementButton && (
                    <button
                        id="increment"
                        aria-label="increment"
                        type="button"
                        onClick={() => increment(1)}
                        disabled={disabled}
                    >
                        <i className="fa-solid fa-chevron-up" />
                    </button>
                )}
                {decrementButton && (
                    <button
                        id="decrement"
                        aria-label="decrement"
                        type="button"
                        onClick={() => increment(-1)}
                        disabled={disabled}
                    >
                        <i className="fa-solid fa-chevron-down" />
                    </button>
                )}
            </ArrowButtons>
        )
    }

    if (!incrementButton && !decrementButton) {
        return renderNumberInput()
    }

    return (
        <NumberInputWrapper>
            {renderNumberInput()}
            {renderIncrementButtons()}
        </NumberInputWrapper>
    )
}
