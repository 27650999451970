import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

import { PasswordInputWrapper } from './PasswordInputField.styled'

interface PasswordInputFieldProps {
    value: string
    isInvalid?: boolean
    onChange?: (value: string) => void
    placeholder?: string
    required?: boolean
    disabled?: boolean
    invalidFeedback?: string
}

export default function PasswordInputField(
    passwordInputFieldProps: PasswordInputFieldProps,
) {
    const {
        value,
        isInvalid,
        onChange,
        placeholder,
        required,
        disabled,
        invalidFeedback,
    } = passwordInputFieldProps

    const [showPassword, setShowPassword] = useState(false)

    if (value === null) {
        return null
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    return (
        <PasswordInputWrapper controlId={placeholder}>
            <Form.Control
                {...{
                    id: placeholder,
                    placeholder,
                    type: showPassword ? 'text' : 'password',
                    value,
                    required,
                    disabled,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        onChange && onChange(e.target.value),
                    isInvalid,
                    className: isInvalid ? 'no-invalid-icon' : '',
                }}
            />
            <button
                type="button"
                className="hide-show-pw"
                onClick={() => toggleShowPassword()}
                aria-label={showPassword ? 'Hide password' : 'Show password'}
            >
                <i
                    className={
                        !showPassword ? 'far fa-eye' : 'far fa-eye-slash'
                    }
                />
            </button>
            {isInvalid && invalidFeedback && (
                <Form.Control.Feedback type="invalid">
                    {invalidFeedback}
                </Form.Control.Feedback>
            )}
        </PasswordInputWrapper>
    )
}
