import React from 'react'

import BootstrapTable from 'apps/shared/components/BootstrapTable'
import { StatusIcon, StatusType } from 'apps/shared/components/Icons/StatusIcon'
import { TableWrapper } from 'apps/vendor/components/Tables/Tables.styled'
import { LicenseType } from 'apps/vendor/interfaces/subscriptions'
import { getStatusType } from 'apps/vendor/utils/statusType'

import {
    InformationCardContent,
    InformationCardWrapper,
    InformationCardIcon,
    IconAndTitleWrapper,
} from '../InformationCard.styled'

type DashboardCardProps = {
    licenses: LicenseType[]
}

type TableDataType = {
    reason: string
    infoQuantity: JSX.Element
}

export default function OverviewCard({ licenses }: DashboardCardProps) {
    const activeLicenses = licenses.filter(
        (license) => license.invoice_status !== '-',
    )

    const data: TableDataType[] = activeLicenses.reduce(
        (acc: TableDataType[], license) => {
            let status = license.invoice_status
            if (status) {
                if (status === 'Transfer created') {
                    status = 'Paid'
                }

                const existingEntry = acc.find(
                    (entry) => entry.reason === status,
                )
                if (existingEntry) {
                    const quantityText =
                        parseInt(existingEntry.infoQuantity.props.text, 10) + 1

                    existingEntry.infoQuantity = (
                        <StatusIcon
                            type={getStatusType(status) || StatusType.PRIMARY}
                            text={quantityText}
                        />
                    )
                } else {
                    acc.push({
                        reason: status,
                        infoQuantity: (
                            <StatusIcon
                                type={
                                    getStatusType(status) || StatusType.PRIMARY
                                }
                                text="1"
                            />
                        ),
                    })
                }
            }

            return acc
        },
        [],
    )

    const accessors = ['reason', 'infoQuantity']

    return (
        <InformationCardWrapper>
            <IconAndTitleWrapper>
                <InformationCardIcon className="fa-regular fa-list-dropdown" />
                <p className="title">Overview Invoices</p>
            </IconAndTitleWrapper>
            <InformationCardContent>
                {activeLicenses.length > 0 ? (
                    <TableWrapper numberOfColumns={2}>
                        <BootstrapTable data={data} accessors={accessors} />
                    </TableWrapper>
                ) : (
                    <p>You have no active invoices.</p>
                )}
            </InformationCardContent>
        </InformationCardWrapper>
    )
}
