import React from 'react'

import { getStatusType } from 'apps/vendor/utils/statusType'

import { StatusIcon } from '../StatusIcon'

interface IconProps {
    status: string
}
export default function InvoiceStatusBadge(defaultProps: IconProps) {
    const { status } = defaultProps
    const badgeType = getStatusType(status)

    if (!badgeType) {
        return <div>-</div>
    }

    return <StatusIcon type={badgeType} text={status} />
}
