import { Button } from 'react-bootstrap'

import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const DragDropFileContainer = styled.div`
    border: 4px dashed ${secondary.lightGray};
    height: 300px;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: border-color 0.3s;

    &.loading {
        > * {
            justify-content: center;
            align-items: center;
            justify-items: center;
        }
    }

    &.uploadLogo:not(.isDisabled) {
        &:hover {
            border-color: ${primary.lightBlue};
            cursor: pointer;
        }
    }

    > img {
        max-width: 200px;
        max-height: 180px;
        margin: 8px;
        margin-bottom: 40px;
    }

    > button {
        margin-top: 16px;
    }
`

export const DragAndDropWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${primary.lightGray};

    .icon {
        font-size: 3rem;
    }
`

export const DragAndDropText = styled.span`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;

    > p {
        font-size: 1rem;
    }
`

export const DragAndDropButton = styled(Button)`
    margin-bottom: 16px;
    height: 40px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
`

export const ViewLogoContainer = styled.div`
    display: grid;
    justify-items: center;
    grid-template-rows: auto 1fr;
    align-items: center;
    height: 300px;
    width: 100%;
    margin-bottom: 20px;
`
