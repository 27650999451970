import React, { useEffect, useState } from 'react'
import { Container, Tab } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import { getArticles } from 'api/articles'
import { getCustomers } from 'api/customers'
import { getInvitations } from 'api/invitations'
import { getLicenses } from 'api/licenses'
import { getMarginStructures } from 'api/marginStructures'
import { getPartners } from 'api/partners'
import { TabWrapper } from 'apps/shared/shared.styled'
import InvitationList from 'apps/vendor/components/InvitationList'
import MarginStructureList from 'apps/vendor/components/MarginStructureList'
import PartnerList, { PartnerType } from 'apps/vendor/components/PartnerList'
import { InvitationListType } from 'apps/vendor/interfaces/invitations'
import {
    CustomerType,
    LicenseType,
    MarginStructureType,
} from 'apps/vendor/interfaces/subscriptions'

import { ArticleType, ArticlesPage } from '../Articles'
import { CustomersPage } from '../Customers'
import { LicensesPage } from '../Licenses'

enum whitelistedTabs {
    PARTNERS = 'partners',
    INVITATIONS = 'invitations',
    MARGINS = 'marginStructures',
    CUSTOMERS = 'customers',
    LICENSES = 'licenses',
    ARTICLES = 'articles',
}

export default function AdministrationPage() {
    const [view, setView] = useState('partners')
    const { tab } = useParams()
    const [partners, setPartners] = useState<PartnerType[] | null>(null)

    const [invitations, setInvitations] = useState<InvitationListType | null>(
        null,
    )

    const [marginStructures, setMarginStructures] = useState<
        MarginStructureType[] | null
    >(null)

    const [customers, setCustomers] = useState<CustomerType[] | null>(null)
    const [licenses, setLicenses] = useState<LicenseType[] | null>(null)
    const [articles, setArticles] = useState<ArticleType[] | null>(null)

    useEffect(() => {
        if (tab) {
            const whitelist = Object.values(whitelistedTabs)

            const match = whitelist.filter((item) => {
                return tab.match(item)
            })

            if (match.length > 0) {
                setView(tab)
            }
        }

        Promise.all([
            getPartners(),
            getInvitations(),
            getMarginStructures(),
            getCustomers(),
            getLicenses(),
            getArticles(),
        ]).then(
            ([
                partnersRes,
                invitationsRes,
                marginStructuresRes,
                customersRes,
                licensesRes,
                articlesRes,
            ]) => {
                setPartners(partnersRes.data)
                setInvitations(invitationsRes.data)
                setMarginStructures(marginStructuresRes.data)
                setCustomers(customersRes.data)
                setLicenses(licensesRes.data)
                setArticles(articlesRes.data)
            },
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleToggleView = (val: string) => {
        if (val === view) {
            return
        }

        setView(val)
    }

    const renderInvitations = () => {
        if (view === 'invitations' && invitations) {
            return (
                <InvitationList
                    updateInvitations={setInvitations}
                    invitations={invitations}
                />
            )
        }

        return null
    }

    const renderMarginstructures = () => {
        if (view === 'marginStructures' && marginStructures) {
            return (
                <MarginStructureList
                    updateMarginStructures={setMarginStructures}
                    marginStructures={marginStructures}
                />
            )
        }

        return null
    }

    const renderCustomers = () => {
        if (view === 'customers' && customers) {
            return (
                <CustomersPage
                    updateCustomers={setCustomers}
                    customers={customers}
                />
            )
        }

        return null
    }

    const renderLicenses = () => {
        if (
            view === 'licenses' &&
            licenses &&
            articles &&
            partners &&
            marginStructures &&
            customers
        ) {
            return (
                <LicensesPage
                    updateLicenses={setLicenses}
                    licenses={licenses}
                    articles={articles}
                    marginStructures={marginStructures}
                    partners={partners}
                    customers={customers}
                />
            )
        }

        return null
    }

    const renderArticles = () => {
        if (view === 'articles' && articles) {
            return (
                <ArticlesPage
                    updateArticles={setArticles}
                    articles={articles}
                />
            )
        }

        return null
    }

    const renderPartners = () => {
        if (view === 'partners' && partners) {
            return (
                <PartnerList updatePartners={setPartners} partners={partners} />
            )
        }

        return null
    }

    return (
        <div>
            <TabWrapper
                activeKey={view}
                onSelect={(k) => handleToggleView(k || 'partners')}
            >
                <Tab
                    title="Partners"
                    eventKey="partners"
                    id="0"
                    className="active"
                />
                <Tab title="Invitations" eventKey="invitations" id="1" />
                <Tab
                    title="Margins Structures"
                    eventKey="marginStructures"
                    id="2"
                />
                <Tab title="Customers" eventKey="customers" id="3" />
                <Tab title="Licenses" eventKey="licenses" id="4" />
                <Tab title="Articles" eventKey="articles" id="5" />
            </TabWrapper>
            <Container fluid className="p-2">
                {renderInvitations()}
                {renderMarginstructures()}
                {renderCustomers()}
                {renderLicenses()}
                {renderArticles()}
                {renderPartners()}
            </Container>
        </div>
    )
}
