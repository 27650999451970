import { createAsyncThunk } from '@reduxjs/toolkit'
import { userLogin } from 'api/users'
import { loginUserParams } from 'api/utils/interfaces'

import loadUser from './loadUser'

const loginUser = createAsyncThunk(
    'user/loginUser',
    async (params: loginUserParams, { dispatch }) => {
        return userLogin(params).then(() => dispatch(loadUser()))
    },
)

export default loginUser
