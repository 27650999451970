import React from 'react'

import { Delete } from './DeleteButton.styled'

export default function DeleteButton(props: { onClick: () => void }) {
    const { onClick } = props

    return (
        <Delete className="fa-solid fa-trash-can" onClick={() => onClick()} />
    )
}
