import { LicenseType, LicenseStatus, Term } from '../interfaces/subscriptions'
import localeNumber from './localeNumber'

interface LicenseRevenueDataProps {
    licenses: LicenseType[]
    vendorId: number | null
    currency?: string
    locale?: string
}

type LicenseRevenueData = {
    totalRevenue: string
    nextLicenseDate: string | null
}

export default function LicenseRevenueData(
    defaultProps: LicenseRevenueDataProps,
): LicenseRevenueData | null {
    const { licenses, vendorId, currency, locale } = defaultProps
    const selectedCurrency = currency || 'SEK'

    const calculateTotalRevenue = (
        licenseRevenue: number,
        term: Term,
    ): number => {
        switch (term) {
            case Term.ONE_MONTH:
                return licenseRevenue * 12
            case Term.THREE_MONTHS:
                return licenseRevenue * 4
            case Term.SIX_MONTHS:
                return licenseRevenue * 2
            case Term.EIGHTEEN_MONTHS:
                return licenseRevenue * (12 / 18)
            case Term.TWO_YEARS:
                return licenseRevenue * (12 / 24)
            case Term.THREE_YEARS:
                return licenseRevenue * (12 / 36)
            case Term.FIVE_YEARS:
                return licenseRevenue * (12 / 60)
            default: // Term.ONE_YEAR
                return licenseRevenue
        }
    }

    const getVendorPosition = (license: LicenseType): number => {
        const vendorTier = license.sales_tiers.find(
            (tier) => tier.vendor.id === vendorId,
        )

        return vendorTier ? vendorTier.order - 1 : 0
    }

    const getVendorShare = (license: LicenseType) => {
        if (!license.revenue_distribution) return 0

        const vendorPosition = license.sales_tiers
            ? getVendorPosition(license)
            : 0
        const totalPriceShare = license.revenue_distribution[vendorPosition]
        const vendorShare = totalPriceShare?.share_pre_fee

        return vendorShare
    }

    let revenue = 0

    licenses.forEach((license) => {
        if (license.status === LicenseStatus.INACTIVE) return

        const vendorShare = getVendorShare(license)
        const licenseRevenue = calculateTotalRevenue(vendorShare, license.term)
        revenue += licenseRevenue
    })

    if (revenue === 0) return null

    const formattedTotalRevenue = revenue
        ? `${localeNumber(revenue, {
              disableFractionDigits: true,
              locale,
          })} ${selectedCurrency}`
        : '---'

    const sortedLicenses = licenses
        .filter((license): license is LicenseType =>
            Boolean(license.next_invoice_due_date),
        )
        .sort((a, b) => {
            const dateA = new Date(a.next_invoice_due_date ?? '')
            const dateB = new Date(b.next_invoice_due_date ?? '')

            if (Number.isNaN(dateA) || Number.isNaN(dateB)) {
                return 0
            }

            return dateA.getTime() - dateB.getTime()
        })

    const nextLicense = sortedLicenses[0] || null

    const nextLicenseDate = nextLicense
        ? nextLicense.next_invoice_due_date ?? '---'
        : '---'

    return { totalRevenue: formattedTotalRevenue, nextLicenseDate }
}
