import React from 'react'

export default function StripeBadge() {
    return (
        <img
            src="stripe_logo.png"
            alt="stripe_logo"
            className="stripe_button"
        />
    )
}
