import React from 'react'

import { ConfirmModal } from 'apps/shared/components/Modals'
import { InvitationType } from 'apps/vendor/interfaces/invitations'

function DeleteInvitationBody(props: { invitation: InvitationType }) {
    const { invitation } = props

    return (
        <p>
            Are you sure you want to remove the invitation to{' '}
            {invitation.to_email} ?
        </p>
    )
}

export default function DeleteInvitationModal(props: {
    onClose: () => void
    onSubmit: () => void
    invitation: InvitationType
}) {
    const { onClose, onSubmit, invitation } = props

    return (
        <ConfirmModal
            body={<DeleteInvitationBody invitation={invitation} />}
            onClose={onClose}
            onSubmit={onSubmit}
            customSubmitText="Remove"
        />
    )
}
