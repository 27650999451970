import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { getLogo, uploadLogo, deleteLogo } from 'api/vendors/logos'
import useToast from 'hooks/useToast'

import { ConfirmModal } from '../Modals'
import { ConfirmModalBody } from '../Modals/ConfirmModal/ConfirmModal.styled'
import {
    DragAndDropButton,
    DragAndDropText,
    DragAndDropWrapper,
    DragDropFileContainer,
} from './UploadLogo.styled'

interface DragDropFileProps {
    isDisabled: boolean
}

export default function UploadLogo(defaultProps: DragDropFileProps) {
    const { isDisabled } = defaultProps
    const { successToast, errorToast } = useToast()
    const [logo, setLogo] = useState<File | null>(null)
    const [logoUrl, setLogoUrl] = useState('')
    const [loading, setLoading] = useState(true)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    useEffect(() => {
        getLogo()
            .then((res) => {
                setLogoUrl(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const handleUploadLogo = useCallback(
        (file: File) => {
            if (!file) return

            uploadLogo(file)
                .then(() => {
                    successToast('Logo uploaded successfully')
                })
                .catch((err) => {
                    if (err.response.status === 409) {
                        return errorToast('Logo already exists.')
                    }
                    if (err.response.status !== 500) {
                        return errorToast(
                            'Failed to save logo, please try again.',
                        )
                    }

                    return errorToast(
                        'Unknown error. Please try again and ' +
                            'contact Sharlic support if error persists.',
                    )
                })
        },
        [successToast, errorToast],
    )

    const handleDeleteLogo = () => {
        deleteLogo()
            .then(() => {
                successToast('Removed logo successfully')
                setLogo(null)
                setLogoUrl('')
                setShowDeleteModal(!showDeleteModal)
            })
            .catch((err) => {
                if (err.response.status === 409) {
                    return errorToast('Logo already exists.')
                }
                if (err.response.status !== 500) {
                    return errorToast('Failed to save logo, please try again.')
                }

                return errorToast(
                    'Unknown error. Please try again and ' +
                        'contact Sharlic support if error persists.',
                )
            })
    }

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            acceptedFiles.forEach((file: File) => {
                const reader = new FileReader()

                reader.onload = () => {
                    setLogo(file)
                    handleUploadLogo(file)
                }

                reader.readAsDataURL(file)
            })
        },
        [handleUploadLogo],
    )

    const onDropRejected = useCallback(
        (fileRejections: any) => {
            fileRejections.forEach((file: any) => {
                if (
                    file.errors.some(
                        (error: any) => error.code === 'file-too-large',
                    )
                ) {
                    errorToast(
                        'File is too large. ' +
                            'Make sure the logo is smaller than 512kb.',
                    )
                }
            })
        },
        [errorToast],
    )

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/svg+xml': ['.svg'],
        },
        onDropRejected,
        maxSize: 512000,
        noClick: isDisabled,
        disabled: isDisabled,
    })

    useEffect(() => {
        if (logo) {
            const acceptedFormats = ['image/png', 'image/svg+xml']

            if (!acceptedFormats.includes(logo.type)) {
                setLogoUrl('')

                return
            }

            if (logo.size / 1000 > 512) {
                setLogoUrl('')

                return
            }

            const reader = new FileReader()

            reader.onloadend = () => {
                setLogoUrl(reader.result as string)
            }
            reader.readAsDataURL(logo)
        } else {
            setLogoUrl('')
        }
    }, [logo])

    if (loading) {
        return <DragDropFileContainer />
    }

    const renderUploadLogoSection = () => (
        <DragDropFileContainer
            {...getRootProps()}
            className={`uploadLogo ${isDisabled ? 'isDisabled' : ''}`}
        >
            <input {...getInputProps()} />
            <DragAndDropWrapper>
                <div className="fa-thin fa-cloud-arrow-up icon" />
                <DragAndDropText>
                    <span>Drag&Drop image here</span>
                    <p>or</p>
                    <DragAndDropButton
                        variant="outline-primary"
                        disabled={isDisabled}
                    >
                        Click to select image
                    </DragAndDropButton>
                </DragAndDropText>
            </DragAndDropWrapper>
        </DragDropFileContainer>
    )

    const renderConfirmModalBody = () => {
        return (
            <ConfirmModalBody>
                <section>You are about to delete your logo.</section>
                <section>
                    <b>This action cannot be undone.</b>
                </section>
            </ConfirmModalBody>
        )
    }

    const renderDisplayLogoSection = () => {
        return (
            <>
                <DragDropFileContainer>
                    <img src={logoUrl} alt="logo" />
                    <DragAndDropButton
                        variant="outline-danger"
                        className="remove"
                        onClick={(e: any) => {
                            e.stopPropagation()
                            setShowDeleteModal(!showDeleteModal)
                        }}
                        disabled={isDisabled}
                    >
                        Remove
                    </DragAndDropButton>
                </DragDropFileContainer>
                {showDeleteModal && (
                    <ConfirmModal
                        onClose={() => {
                            setShowDeleteModal(!showDeleteModal)
                            setLogo(null)
                        }}
                        onSubmit={() => handleDeleteLogo()}
                        customSubmitText="Delete"
                        body={renderConfirmModalBody()}
                        deleteButton
                    />
                )}
            </>
        )
    }

    return logoUrl ? renderDisplayLogoSection() : renderUploadLogoSection()
}
