import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const RecoverFrame = styled.div`
    position: fixed;
    width: min(60vw, 400px);
    height: 400px;
    left: calc((100vw - min(72vw, 400px)) / 2);
    bottom: calc((100vh - 400px) / 2);
    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;

    border: 1px solid ${secondary.lightGray};
    border-radius: 10px;
    box-shadow: 0 0 64px rgba(0, 0, 0, 0.05), 0 0 16px rgba(0, 0, 0, 0.05);

    > *:first-child {
        width: 65%;
    }

    > *:nth-child(2) {
        width: 100%;
        margin-bottom: 5px;

        > p {
            font-size: 0.8em;
        }
    }

    > form {
        display: inline-grid;
        gap: 10px;
        flex-direction: column;
        width: 80%;

        > div {
            position: relative;
            height: 40px;

            > .far {
                width: 2em;
                position: absolute;
                top: 0;
                right: 10px;
                margin: 0;

                color: ${primary.gray};
                background-color: transparent;
            }
        }

        > a {
            margin-bottom: 15px;
            text-align: right;
        }
    }

    @media (max-width: 390px) {
        position: fixed;
        width: 80vw;
        height: 450px;
        left: 5vw;
        bottom: calc((100vh - 300px) / 2);

        justify-content: space-between;

        border: none;
        box-shadow: none;

        > *:first-child {
            width: 80%;
        }

        > *:nth-child(2) {
            width: 80%;
            margin-bottom: -20px;
        }

        > form {
            display: inline-grid;
            gap: 25px;
            flex-direction: column;
            width: 90%;

            > div {
                position: relative;
                height: 20px;

                > .far {
                    width: 2em;
                    position: absolute;
                    top: 0;
                    right: 10px;
                    margin: 0;

                    color: ${primary.gray};
                    background-color: transparent;
                }
            }

            > a {
                margin-bottom: 25px;
                text-align: right;
            }
        }
    }
`
