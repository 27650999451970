import { Modal } from 'react-bootstrap'

import styled from 'styled-components'
import { primary } from 'utils/colors'

export const TitleWrapper = styled(Modal.Title)`
    span {
        color: ${primary.gray};
        font-size: 0.9rem;
        border-left: 1px solid ${primary.darkGray};
        margin-left: 10px;
        padding-left: 10px;
    }
`
