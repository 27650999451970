import { InputGroup } from 'react-bootstrap'

import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

interface SalesTierTotalPercentageProps {
    valid: boolean
}

export const SalesTierPercentageWrapper = styled.section`
    position: relative;
    margin-bottom: 0.8rem;
`

export const PercentageInputWrapper = styled.div`
    display: flex;

    .form-text {
        color: ${primary.darkBlue};
    }

    > * {
        width: 18%;
        margin-right: calc(18% * 0.8);
    }
`

export const SalesTierTotalPercentage = styled.p<SalesTierTotalPercentageProps>`
    width: fit-content;
    position: absolute;
    right: 0;
    bottom: -23%;
    font-size: 1.5rem;
    margin-right: 0;
    align-self: flex-end;

    color: ${({ valid }) => (valid ? 'green' : 'red')};
`

export const StyledInputGroupText = styled(InputGroup.Text)`
    justify-content: center;
    background-color: ${secondary.whisperWhite};
    width: 1rem;
`
