import React, { useState } from 'react'

import { getMarginStructures } from 'api/marginStructures'
import deleteMarginStructure from 'api/marginStructures/deleteMarginStructure'
import AccordionItem from 'apps/shared/components/AccordionItem'
import { KebabButton } from 'apps/shared/components/Buttons'
import TextButton from 'apps/shared/components/Buttons/TextButton'
import { EmptyListIcon, BooleanIcon } from 'apps/shared/components/Icons'
import { ConfirmModal } from 'apps/shared/components/Modals'
import { ConfirmModalBody } from 'apps/shared/components/Modals/ConfirmModal/ConfirmModal.styled'
import { MarginStructureType } from 'apps/vendor/interfaces/subscriptions'
import PageHeader from 'apps/vendor/pages/PageHeader'
import {
    AccordionBody,
    AccordionWrapper,
    BadgeRow,
    HeaderRow,
} from 'apps/vendor/pages/pages.styled'
import { formatDate } from 'apps/vendor/utils'
import useToast from 'hooks/useToast'
import useUser from 'hooks/useUser'
import useVendor from 'hooks/useVendor'

import { MarginStructureModal } from '../Modals'

export type VendorType = {
    name: string
    contact_email: string
    stripe_account_linked: boolean
}

export default function MarginStructureList(props: {
    updateMarginStructures: (data: MarginStructureType[]) => void
    marginStructures: MarginStructureType[]
}) {
    const { marginStructures, updateMarginStructures } = props
    const { vendor } = useVendor()
    const { user } = useUser()

    const [showMarginStructureModal, setShowMarginStructureModal] =
        useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [selectedMarginStructure, setSelectedMarginStructure] =
        useState<MarginStructureType | null>(null)

    const { successToast, errorToast } = useToast()

    const updateMarginStructureList = () => {
        getMarginStructures().then((response) => {
            updateMarginStructures(response.data)
        })
        setSelectedMarginStructure(null)
        setShowMarginStructureModal(false)
    }

    const getNumberOfSalesTiers = () => {
        if (!marginStructures) return 0

        let numberOfSalesTiers = 0

        marginStructures.forEach((margin) => {
            numberOfSalesTiers =
                margin.number_of_sales_tiers > numberOfSalesTiers
                    ? margin.number_of_sales_tiers
                    : numberOfSalesTiers
        })

        return numberOfSalesTiers
    }

    const numberOfSalesTiers = getNumberOfSalesTiers()

    const getHeaders = () => {
        return (
            <HeaderRow
                numberOfColumns={numberOfSalesTiers + 1}
                rightJustifiedColumns={[2, 3, 4]}
            >
                <span>Name</span>
                <span>{vendor && vendor.name}</span>
                {numberOfSalesTiers > 2 && <span>Distributor</span>}
                {numberOfSalesTiers > 1 && <span>Reseller</span>}
            </HeaderRow>
        )
    }

    const handleEditMarginStructure = (
        marginStructure: MarginStructureType,
    ) => {
        setSelectedMarginStructure(marginStructure)
        setShowMarginStructureModal(!showMarginStructureModal)
    }

    const handleToggleDeleteModal = (marginStructure: MarginStructureType) => {
        setSelectedMarginStructure(marginStructure)
        setShowDeleteModal(!showDeleteModal)
    }

    const getAccordionHeaders = (
        marginStructure: MarginStructureType,
        percentagePerTier: string[],
    ) => {
        return (
            <BadgeRow
                numberOfColumns={numberOfSalesTiers + 1}
                rightJustifiedColumns={[2, 3, 4]}
            >
                <TextButton
                    onClick={() => handleEditMarginStructure(marginStructure)}
                    text={marginStructure.name}
                />
                <span>{percentagePerTier[0]}%</span>
                {numberOfSalesTiers > 2 && (
                    <span>
                        {percentagePerTier[1] && percentagePerTier.length > 2
                            ? `${percentagePerTier[1]}%`
                            : '-'}
                    </span>
                )}
                {numberOfSalesTiers > 1 && (
                    <span>
                        {(percentagePerTier[2] || percentagePerTier[1])?.concat(
                            '%',
                        ) || '-'}
                    </span>
                )}
            </BadgeRow>
        )
    }

    const getMarginStructureBody = (marginStructure: MarginStructureType) => {
        const dateString = formatDate(
            new Date(marginStructure.created_at),
            user?.locale,
        )

        return (
            <AccordionBody>
                <div>
                    <p>Description</p>
                    <p>{marginStructure.description || '-'}</p>
                </div>
                <div>
                    <p>Created</p>
                    <p>{dateString}</p>
                </div>
                <div>
                    <p className="centered">Available for partners</p>
                    <p className="centered">
                        <BooleanIcon
                            checked={marginStructure.available_for_partners}
                        />
                    </p>
                </div>
                <div>
                    <p className="centered">Default</p>
                    <p className="centered">
                        <BooleanIcon checked={marginStructure.is_default} />
                    </p>
                </div>
                <KebabButton
                    onEdit={() => handleEditMarginStructure(marginStructure)}
                    onDelete={() => handleToggleDeleteModal(marginStructure)}
                />
            </AccordionBody>
        )
    }

    const renderTable = () => {
        if (!marginStructures) return null

        if (marginStructures.length === 0) {
            return <EmptyListIcon />
        }

        return (
            <>
                {getHeaders()}
                <AccordionWrapper alwaysOpen>
                    {marginStructures.map((marginStructure, index) => {
                        const percentagePerTier =
                            marginStructure.percentage_per_tier.split(',')

                        return (
                            <AccordionItem
                                key={marginStructure.id}
                                eventKey={index.toString()}
                                header={getAccordionHeaders(
                                    marginStructure,
                                    percentagePerTier,
                                )}
                                body={getMarginStructureBody(marginStructure)}
                            />
                        )
                    })}
                </AccordionWrapper>
            </>
        )
    }

    const handleDeleteMarginStructure = () => {
        if (selectedMarginStructure) {
            deleteMarginStructure(selectedMarginStructure.id)
                .then(() => {
                    updateMarginStructureList()
                    setSelectedMarginStructure(null)
                    setShowDeleteModal(!showDeleteModal)
                    successToast('Margin structure deleted successfully')
                })
                .catch(() => {
                    return errorToast(
                        // eslint-disable-next-line max-len
                        'Unknown error. Please try again and contact Sharlic support if error persists',
                    )
                })
        }
    }

    const renderDeletionConstraintsWarning = () => {
        return (
            <section>
                <p>
                    This margin structure cannot be deleted because it is
                    currently in use by the following licenses:
                </p>
                <ul>
                    {selectedMarginStructure?.related_licenses?.map(
                        (license) => (
                            <li key={license.name}>
                                <b>{license.name}</b> owned by{' '}
                                <b>{license.vendor}</b>
                            </li>
                        ),
                    )}
                </ul>
            </section>
        )
    }

    const renderConfirmDeleteSection = () => {
        return (
            <>
                <section>
                    You are about to to delete the following margin structure:
                    <p>
                        <b>{selectedMarginStructure?.name}</b>?
                    </p>
                </section>
                <section>
                    <b>This action cannot be undone.</b>
                </section>
            </>
        )
    }

    const marginStructureDeletable = () => {
        return selectedMarginStructure?.related_licenses?.length === 0
    }

    const renderConfirmModalBody = () => {
        let html = renderConfirmDeleteSection()

        if (!marginStructureDeletable()) {
            html = renderDeletionConstraintsWarning()
        }

        return <ConfirmModalBody>{html}</ConfirmModalBody>
    }

    return (
        <div>
            <PageHeader
                onButtonClicked={() =>
                    setShowMarginStructureModal(!showMarginStructureModal)
                }
            />
            {showMarginStructureModal && (
                <MarginStructureModal
                    onClose={() => {
                        setShowMarginStructureModal(!showMarginStructureModal)
                        setSelectedMarginStructure(null)
                    }}
                    onSubmit={() => updateMarginStructureList()}
                    preselectedMarginStructure={selectedMarginStructure}
                />
            )}
            {showDeleteModal && (
                <ConfirmModal
                    onClose={() => {
                        setShowDeleteModal(!showDeleteModal)
                        setSelectedMarginStructure(null)
                    }}
                    onSubmit={() => handleDeleteMarginStructure()}
                    customSubmitText="Delete"
                    body={renderConfirmModalBody()}
                    deleteButton
                    canSubmit={marginStructureDeletable()}
                />
            )}
            {renderTable()}
        </div>
    )
}
