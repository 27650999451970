import React from 'react'

import { NotificationSettings } from 'apps/vendor/interfaces/subscriptions'

import { NotificationSection } from './components'

interface ActionsTabProps {
    handlers: {
        setRenewalNotifications: (renewalNotifications: boolean) => void
        setNotificationSettings: (
            notificationSettings: NotificationSettings[],
        ) => void
    }
    states: {
        partnerOwnedLicense: boolean
        renewalNotifications: boolean
        notificationSettings: NotificationSettings[]
    }
}

export default function ActionsTab(props: ActionsTabProps) {
    const { handlers, states } = props

    const renderNotificationSection = () => {
        if (states.partnerOwnedLicense) {
            return null
        }

        return (
            <NotificationSection
                renewalNotifications={states.renewalNotifications}
                setRenewalNotifications={handlers.setRenewalNotifications}
                notificationSettings={states.notificationSettings}
                setNotificationSettings={handlers.setNotificationSettings}
            />
        )
    }

    return renderNotificationSection()
}
