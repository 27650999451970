import React, { useEffect, useMemo, useState } from 'react'

import getSupportedCountries from 'api/countries/getCountries'
import { CompanyInformation, CountryType } from 'api/utils/interfaces'
import {
    TextInputField,
    PhoneInputField,
} from 'apps/shared/components/FormInputFields'
import {
    LabelAndInputContainer,
    RegisterForm,
    CompanyInfoSection,
    CompanyInfoSectionContent,
    CompanyInfoSectionHeader,
} from 'apps/shared/pages/Register/Register.styled'
import { DoubleSection, FormLabel } from 'apps/shared/shared.styled'
import { SelectDropdown } from 'apps/vendor/components/Selects'
import { CountryCode } from 'apps/vendor/interfaces/subscriptions'
import { CountryCode as PhoneCountryCode } from 'libphonenumber-js'
import isEmail from 'validator/lib/isEmail'

type CompanyInformationProps = {
    onCompanyInformationChange: (
        companyInformation: CompanyInformation,
        companyInformationValid: boolean,
    ) => void
    contactEmail: string
    companyInformation: CompanyInformation
}

export default function CompanyInformationForm(
    defaultProps: CompanyInformationProps,
) {
    const { onCompanyInformationChange, contactEmail, companyInformation } =
        defaultProps
    const [isContactInfoVisible, setContactInfoVisible] = useState(false)
    const [isAddressVisible, setAddressVisible] = useState(false)
    const [isLegalInfoVisible, setLegalInformationVisible] = useState(true)

    const toggleContactInfoVisibility = () => {
        setContactInfoVisible(!isContactInfoVisible)
    }

    const toggleAddressVisibility = () => {
        setAddressVisible(!isAddressVisible)
    }

    const toggleLegalInfoVisibility = () => {
        setLegalInformationVisible(!isLegalInfoVisible)
    }

    const [reference, setReference] = useState(
        companyInformation?.reference || '',
    )

    const [companyName, setCompanyName] = useState(
        companyInformation?.company_name || '',
    )

    const [addressLineOne, setAddressLineOne] = useState(
        companyInformation?.address_line_1 || '',
    )

    const [addressLineTwo, setAddressLineTwo] = useState(
        companyInformation?.address_line_2 || '',
    )

    const [zipCode, setZipCode] = useState(companyInformation?.zip_code || '')
    const [city, setCity] = useState(companyInformation?.city || '')
    const [state, setState] = useState(companyInformation?.state || '')

    const [organizationNumber, setOrganizationNumber] = useState(
        companyInformation?.organization_number || '',
    )

    const [invoiceEmail, setInvoiceEmail] = useState(
        companyInformation?.invoice_email || '',
    )

    const [vendorPhone, setVendorPhone] = useState(
        companyInformation?.vendor_phone || '',
    )

    const [selectedCountry, setSelectedCountry] = useState<CountryType | null>(
        companyInformation?.country || null,
    )

    const [countryChoices, setCountryChoices] = useState([])
    const [phoneNumberError, setPhoneNumberError] = useState('')
    const [contactEmailError, setContactEmailError] = useState(false)
    const [invoiceEmailError, setInvoiceEmailError] = useState(false)
    const [organizationNumberError, setOrganizationNumberError] = useState(true)
    const [companyInfoValid, setCompanyInfoValid] = useState(false)

    const companyInfo = useMemo(
        () => ({
            reference,
            company_name: companyName,
            address_line_1: addressLineOne,
            address_line_2: addressLineTwo,
            zip_code: zipCode,
            city,
            state,
            organization_number: organizationNumber,
            contact_email: contactEmail,
            invoice_email: invoiceEmail,
            vendor_phone: vendorPhone,
            country: selectedCountry || {
                code: CountryCode.SE,
                name: '',
            },
        }),
        [
            reference,
            companyName,
            addressLineOne,
            addressLineTwo,
            zipCode,
            city,
            state,
            organizationNumber,
            contactEmail,
            invoiceEmail,
            vendorPhone,
            selectedCountry,
        ],
    )

    useEffect(() => {
        if (selectedCountry !== null) {
            onCompanyInformationChange(companyInfo, companyInfoValid)
        }
    }, [
        onCompanyInformationChange,
        companyInfo,
        companyInfoValid,
        selectedCountry,
    ])

    useEffect(() => {
        getSupportedCountries().then((res) =>
            setCountryChoices(res.data.countries),
        )
    }, [])

    useEffect(() => {
        setContactEmailError(!!contactEmail && !isEmail(contactEmail))

        setInvoiceEmailError(!!invoiceEmail && !isEmail(invoiceEmail))

        setOrganizationNumberError(
            !!organizationNumber &&
                (organizationNumber.length < 8 ||
                    organizationNumber.length > 10),
        )

        const vendorFormValid =
            companyName &&
            contactEmail &&
            invoiceEmail &&
            !contactEmailError &&
            !invoiceEmailError &&
            !phoneNumberError &&
            !organizationNumberError &&
            reference &&
            selectedCountry &&
            addressLineOne &&
            zipCode &&
            city &&
            organizationNumber &&
            (state || selectedCountry.code !== 'US')

        setCompanyInfoValid(Boolean(vendorFormValid))
    }, [
        addressLineOne,
        city,
        companyName,
        contactEmail,
        contactEmailError,
        invoiceEmail,
        invoiceEmailError,
        organizationNumber,
        organizationNumberError,
        phoneNumberError,
        reference,
        selectedCountry,
        state,
        zipCode,
    ])

    const countries: CountryType[] =
        countryChoices?.map((country) => ({
            code: country[0],
            name: country[1],
        })) || []

    const renderPhoneSection = (
        phoneNumber: string,
        setPhoneNumber: (phone: string) => void,
        country?: PhoneCountryCode,
    ) => {
        return (
            <LabelAndInputContainer>
                <FormLabel>
                    Phone number <span>- Optional</span>
                </FormLabel>
                <PhoneInputField
                    {...{
                        phoneNumber,
                        onChange: (phoneInput, errorMessage) => {
                            setPhoneNumber(phoneInput)
                            setPhoneNumberError(errorMessage)
                        },
                        selectedCountry: country as PhoneCountryCode,
                    }}
                />
            </LabelAndInputContainer>
        )
    }

    const handleSetSelectedCountry = (countryCode: string) => {
        const chosenCountry = countryChoices.find(
            (country) => country[0] === countryCode,
        )
        if (chosenCountry) {
            setSelectedCountry({
                code: chosenCountry[0],
                name: chosenCountry[1],
            })
        }
    }

    const renderCountryDropdown = () => {
        const options = countries.map((country) => ({
            value: country.code,
            label: country.name,
        }))

        const selectedOption = options.find(
            (option) => option.value === selectedCountry?.code,
        )

        const value = selectedOption || null

        return (
            <>
                <FormLabel>Country</FormLabel>
                <SelectDropdown
                    options={options}
                    value={value}
                    onChange={handleSetSelectedCountry}
                    placeholder="Select Country"
                    isSearchable
                />
            </>
        )
    }

    const renderLabelAndInput = (
        value: string,
        label: string,
        id: string,
        onChange?: (value: string) => void,
        autoFocus?: boolean,
        isInvalid?: boolean,
        invalidFeedback?: string,
    ) => {
        const isOptional = id === 'address-line-2'

        return (
            <LabelAndInputContainer>
                <FormLabel>
                    {label} {isOptional && <span>- Optional</span>}
                </FormLabel>
                <TextInputField
                    value={value}
                    id={id}
                    onChange={onChange}
                    required={!isOptional}
                    autoFocus={autoFocus}
                    isInvalid={isInvalid}
                    disabled={id === 'contact-email'}
                    invalidFeedback={invalidFeedback}
                />
            </LabelAndInputContainer>
        )
    }

    const renderCompanyInfoSectionHeader = (
        toggleVisibility: () => void,
        visibleOnTab: (visible: boolean) => void,
        isSectionVisible: boolean,
        header: string,
    ) => {
        return (
            <CompanyInfoSectionHeader
                onClick={toggleVisibility}
                onKeyDown={(e) => {
                    if (e.key === 'Tab' || e.key === ' ') {
                        visibleOnTab(true)
                    }
                }}
                tabIndex={0}
            >
                {header} {isSectionVisible ? '▲' : '▼'}
            </CompanyInfoSectionHeader>
        )
    }

    const renderLegalInfoSection = () => {
        return (
            <CompanyInfoSection>
                {renderCompanyInfoSectionHeader(
                    toggleLegalInfoVisibility,
                    setLegalInformationVisible,
                    isLegalInfoVisible,
                    'Legal Information',
                )}
                {isLegalInfoVisible && (
                    <CompanyInfoSectionContent>
                        <CompanyInfoSection className="child-section">
                            {renderLabelAndInput(
                                companyName,
                                'Company Name',
                                'company-name',
                                setCompanyName,
                                true,
                            )}
                        </CompanyInfoSection>
                        <CompanyInfoSection className="child-section">
                            {renderLabelAndInput(
                                organizationNumber,
                                'Organization Number',
                                'organization-number',
                                setOrganizationNumber,
                                false,
                                organizationNumberError,
                                'Number must be between 8 and 10 characters',
                            )}
                        </CompanyInfoSection>
                    </CompanyInfoSectionContent>
                )}
            </CompanyInfoSection>
        )
    }

    const renderContactInfoSection = () => {
        return (
            <CompanyInfoSection>
                {renderCompanyInfoSectionHeader(
                    toggleContactInfoVisibility,
                    setContactInfoVisible,
                    isContactInfoVisible,
                    'Contact Information',
                )}
                {isContactInfoVisible && (
                    <CompanyInfoSectionContent>
                        <CompanyInfoSection className="child-section">
                            {renderLabelAndInput(
                                contactEmail,
                                'Contact Email',
                                'contact-email',
                            )}
                        </CompanyInfoSection>
                        <CompanyInfoSection className="child-section">
                            {renderLabelAndInput(
                                reference,
                                'Reference (Contact Person)',
                                'reference-person',
                                setReference,
                            )}
                            {renderPhoneSection(vendorPhone, setVendorPhone)}
                        </CompanyInfoSection>
                        <CompanyInfoSection className="child-section">
                            {renderLabelAndInput(
                                invoiceEmail,
                                'Invoice Email',
                                'invoice-email',
                                setInvoiceEmail,
                                false,
                                invoiceEmailError,
                            )}
                        </CompanyInfoSection>
                    </CompanyInfoSectionContent>
                )}
            </CompanyInfoSection>
        )
    }

    const renderCompanyAddressSection = () => {
        return (
            <CompanyInfoSection>
                {renderCompanyInfoSectionHeader(
                    toggleAddressVisibility,
                    setAddressVisible,
                    isAddressVisible,
                    'Company Address',
                )}
                {isAddressVisible && (
                    <CompanyInfoSectionContent>
                        <CompanyInfoSection className="child-section">
                            {renderCountryDropdown()}
                        </CompanyInfoSection>
                        <CompanyInfoSection className="child-section">
                            <DoubleSection>
                                {renderLabelAndInput(
                                    addressLineOne,
                                    'Address Line 1',
                                    'address-line-1',
                                    setAddressLineOne,
                                )}
                                {renderLabelAndInput(
                                    addressLineTwo,
                                    'Address Line 2',
                                    'address-line-2',
                                    setAddressLineTwo,
                                )}
                            </DoubleSection>
                        </CompanyInfoSection>
                        <CompanyInfoSection className="child-section">
                            <DoubleSection>
                                {renderLabelAndInput(
                                    zipCode,
                                    'Zip Code',
                                    'zip-code',
                                    setZipCode,
                                )}
                                {renderLabelAndInput(
                                    city,
                                    'City',
                                    'city',
                                    setCity,
                                )}
                            </DoubleSection>
                        </CompanyInfoSection>
                        {selectedCountry?.code === 'US' && (
                            <CompanyInfoSection className="child-section">
                                {renderLabelAndInput(
                                    state,
                                    'State',
                                    'state',
                                    setState,
                                )}
                            </CompanyInfoSection>
                        )}
                    </CompanyInfoSectionContent>
                )}
            </CompanyInfoSection>
        )
    }

    return (
        <RegisterForm>
            <h1>Company Information</h1>
            <form>
                {renderLegalInfoSection()}
                {renderContactInfoSection()}
                {renderCompanyAddressSection()}
            </form>
        </RegisterForm>
    )
}
