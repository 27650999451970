import styled from 'styled-components'
import { primary } from 'utils/colors'

export const NumberInputWrapper = styled.div`
    gap: 0.5rem;
`

export const ArrowButtons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
        all: unset;

        :hover {
            color: ${primary.gray};
        }
        :active {
            color: ${primary.darkGray};
        }

        > i {
            font-size: 1.1rem;
            text-shadow: 0px 1px 0.6px;
        }
    }
`
