import React from 'react'

import { StatusIconWrapper } from './StatusIcon.styled'

export enum StatusType {
    SUCCESS = 'success',
    WARNING = 'warning',
    DANGER = 'danger',
    PRIMARY = 'primary',
}

interface IconProps {
    type?: StatusType | 'secondary'
    text?: string | number
}

export default function StatusIcon(defaultProps: IconProps) {
    const { type, text } = defaultProps

    return (
        <StatusIconWrapper bg={type?.toLowerCase() || 'secondary'}>
            {text}
        </StatusIconWrapper>
    )
}
