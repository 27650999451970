import React from 'react'

import { EmptyListIconWrapper } from 'apps/vendor/pages/pages.styled'

interface EmptyListProps {
    style?: React.CSSProperties
    insideModal?: boolean
}

export default function EmptyListIcon(defaultProps: EmptyListProps) {
    const { style, insideModal } = defaultProps

    return (
        <EmptyListIconWrapper style={style} insideModal={insideModal}>
            ---
        </EmptyListIconWrapper>
    )
}
