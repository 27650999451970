import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const InformationCardWrapper = styled.div`
    padding: 16px 10px 0 20px;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: ${secondary.matteDeepBlue};
`

export const InformationCardIcon = styled.i`
    font-size: 2.5em;
`

export const IconAndTitleWrapper = styled.div`
    display: flex;
    margin-bottom: 10px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    gap: 10px;

    .title {
        font-size: 1.2rem;
        margin-bottom: 20px;
        color: ${primary.darkGray};
    }
`

export const InformationCardContent = styled.div`
    padding-top: 10px;
    border-radius: 10px;
    text-align: start;

    overflow: hidden;

    @media screen and (max-width: 1000px) {
        > Table {
            thead {
                td {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 20px;
                }
            }
            tbody {
                max-width: 100%;
                font-size: small;
            }
        }
    }
`
