import { API } from 'api'

export default function uploadLogo(file: File): Promise<any> {
    const formData = new FormData()
    formData.append('logo', file)

    return API.post(`/vendors/logo/`, formData, {
        'Content-Type': 'multipart/form-data',
    })
}
