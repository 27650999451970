import React from 'react'

import useUser from 'hooks/useUser'
import useVendor from 'hooks/useVendor'

import {
    DashboardHeaderWrapper,
    DashboardHeaderContent,
} from './DashboardHeader.styled'

export default function DashBoardHeader() {
    const vendor = useVendor()
    const user = useUser()
    const vendorName = vendor.vendor?.name || ''
    const firstName = user.user?.first_name || ''
    const lastName = user.user?.last_name || ''

    return (
        <DashboardHeaderWrapper>
            <DashboardHeaderContent>
                <p className="title">{`Welcome, ${firstName} ${lastName}`}</p>
                <h3 className="vendor">{vendorName}</h3>
            </DashboardHeaderContent>
        </DashboardHeaderWrapper>
    )
}
