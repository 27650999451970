import React from 'react'

import { Decline } from './DeclineButton.styled'

export default function DeclineButton(props: { onClick: () => void }) {
    const { onClick } = props

    return (
        <Decline
            className="fa-solid fa-circle-xmark"
            onClick={() => onClick()}
        />
    )
}
