import styled from 'styled-components'
import { secondary } from 'utils/colors'

export const NotificationCheckboxes = styled.section`
    > div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
`

export const NotificationSectionWrapper = styled.div`
    border: 1px solid ${secondary.lightGray};
    border-radius: 8px;
    padding: 0.5rem;

    .react-multi-email {
        max-height: fit-content;
        padding: 0, 0.4rem;

        > span[data-placeholder] {
        }

        > * {
            height: 100%;
            border-radius: 5px;
        }
    }
`
