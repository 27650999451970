import styled from 'styled-components'

export const InvitationAccordionBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > p {
        margin: 0;
    }

    > :last-child {
        font-size: 1.2rem;
        margin-left: auto;
    }
`
