import React from 'react'

import BootstrapTable from 'apps/shared/components/BootstrapTable'
import { KebabButton } from 'apps/shared/components/Buttons'
import { LicenseType } from 'apps/vendor/interfaces/subscriptions'
import { localeNumber } from 'apps/vendor/utils'
import useUser from 'hooks/useUser'

import { ArticleSetType } from '../../Modals/LicenseModal'
import { TableWrapper } from '../Tables.styled'

export default function LicensesTable(props: {
    license: LicenseType
    editLicense: (license: LicenseType) => void
    deleteLicense: (license: LicenseType) => void
}): JSX.Element {
    const { license, editLicense, deleteLicense } = props
    const { user } = useUser()

    const getHeaders = () => {
        return ['Article', 'Quantity', 'Unit MSRP', 'Total MSRP', 'Currency']
    }

    const getData = () => {
        return license.articles.map((articleSet: ArticleSetType) => ({
            article: {
                name: articleSet.article.name,
                msrp: articleSet.article.msrp,
                currency: articleSet.article.currency,
            },
            quantity: articleSet.quantity,
            unitMsrp: localeNumber(Number(articleSet.article.msrp), {
                locale: user?.locale,
            }),
            totalArticleMSRP: localeNumber(
                articleSet.quantity * Number(articleSet.article.msrp),
                { locale: user?.locale },
            ),
        }))
    }

    const getAccessors = () => {
        return [
            'article.name',
            'quantity',
            'unitMsrp',
            'totalArticleMSRP',
            'article.currency',
        ]
    }

    return (
        <TableWrapper numberOfColumns={5}>
            <BootstrapTable
                striped
                headers={getHeaders()}
                data={getData()}
                accessors={getAccessors()}
                rightAlignedHeaders={[
                    'Quantity',
                    'Unit MSRP',
                    'Total MSRP',
                    'Currency',
                ]}
            />
            <KebabButton
                onEdit={() => editLicense(license)}
                onDelete={() => deleteLicense(license)}
            />
        </TableWrapper>
    )
}
