import styled from 'styled-components'
import 'react-phone-input-2/lib/style.css'

export const BillingSection = styled.div`
    > * {
        margin-bottom: 0.5rem;
    }
    > :nth-child(5) {
        margin-bottom: 0;
    }
`
export const WarningMessage = styled.p`
    font-size: 0.85rem;
    font-style: italic;
`
