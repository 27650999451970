import { useEffect } from 'react'

import LoadingStatus from 'state/enums/LoadingStatus'
import { loadUser } from 'state/user/actions'

import { UseUserInterface } from './types/useUser.types'
import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'

const useUser = () => {
    const dispatch = useAppDispatch()
    const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn)
    const status = useAppSelector((state) => state.user.status)
    const user = useAppSelector((state) => state.user.data)

    useEffect(() => {
        if (status === LoadingStatus.IDLE) {
            dispatch(loadUser())
        }
    }, [status, dispatch])

    return { isLoggedIn, user, status } as UseUserInterface
}

export default useUser
