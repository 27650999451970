import { InputGroup } from 'react-bootstrap'

import styled from 'styled-components'
import { primary } from 'utils/colors'

export const EmailContainer = styled(InputGroup)`
    padding: 0;
`

export const Envelope = styled(InputGroup.Text)`
    color: ${primary.darkGray};
`
