import styled from 'styled-components'
import { primary } from 'utils/colors'

export const DashboardHeaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 20px 10px 12px 20px;
    color: ${primary.darkBlue};
`

export const DashboardHeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;

    > .title {
        font-size: 1rem;
        margin-bottom: 0;
    }

    > .description {
        font-size: 0.7rem;
        color: ${primary.darkGray};
    }
`
