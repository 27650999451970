import React from 'react'

import { ErrorBadgeWrapper } from './ErrorBadge.styled'

export default function ErrorBadge(errorMessage: string) {
    return (
        <ErrorBadgeWrapper
            className="alert alert-danger text-center"
            role="alert"
        >
            {errorMessage}
        </ErrorBadgeWrapper>
    )
}
