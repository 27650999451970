import React from 'react'
import { Form } from 'react-bootstrap'

import { Envelope, EmailContainer } from './EmailInput.styled'

interface EmailProps {
    value: string
    onChange: (value: string, optionalValue?: string) => void
    placeholder?: string
    onBlur?: () => void
    optionalValue?: string
    displayIcon?: boolean
    disabled?: boolean
}

export default function EmailInput(defaultProps: EmailProps) {
    const {
        value,
        placeholder,
        onChange,
        onBlur,
        optionalValue,
        displayIcon,
        disabled,
    } = defaultProps

    return (
        <EmailContainer>
            {displayIcon && (
                <Envelope>
                    <div className="fa-regular fa-envelope-open" />
                </Envelope>
            )}
            <Form.Control
                placeholder={placeholder}
                value={value}
                type="email"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(e.target.value, optionalValue)
                }
                onBlur={onBlur}
                disabled={disabled}
            />
        </EmailContainer>
    )
}
