import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const MapMarker = styled.div`
    border-radius: 50%;
    font-size: 1.5em;
    color: ${primary.blue};

    &.customer {
        color: ${primary.orange};
        font-size: 2em;
    }
`

export const MapContainerWrapper = styled.div`
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    padding: 1px;
    border: 1px solid ${secondary.whisperWhite};
    background: ${secondary.almostWhiteGray};

    @media screen and (max-width: 768px) {
        display: none;
    }
`

export const MarkerInfo = styled.div`
    > h3 {
        margin: 0;
        font-size: 1em;
    }
    > p {
        margin: 0;
    }
    > p:last-child {
        font-style: italic;
    }
`
