import React from 'react'

import { EditableTextButton } from 'apps/shared/components/Buttons'
import NumberInput from 'apps/shared/components/FormInputFields/NumberInput'
import { EmptyListIcon } from 'apps/shared/components/Icons'
import { FormLabel, WarningMessage } from 'apps/shared/shared.styled'
import ArticlesDropdownContainer from 'apps/vendor/components/ArticlesDropdownContainer'
import { ArticleSetType } from 'apps/vendor/components/Modals/LicenseModal/LicenseModal'
import {
    Checkbox,
    MultipleSection,
    RemoveListItemButton,
} from 'apps/vendor/components/Modals/Modals.styled'
import { PartnerType } from 'apps/vendor/components/PartnerList'
import { SelectDropdown } from 'apps/vendor/components/Selects'
import { CustomerType } from 'apps/vendor/interfaces/subscriptions'
import { ArticleType } from 'apps/vendor/pages/Articles'
import { localeNumber } from 'apps/vendor/utils'
import { User } from 'state/user/UserState'

import {
    ArticleList,
    RadioButtons,
    LicenseListItem,
    TotalPriceSection,
    PriceAdjustmentSection,
    ArticleSetWrapper,
    ArticlesSection,
} from './ArticlesTab.styled'

interface ArticlesTabProps {
    handlers: {
        handleSetSelectedPartner: (index: number) => void
        handleArticleRadioChange: () => void
        handleAddArticle: (article: ArticleType) => void
        handleRemoveArticle: (article: ArticleType) => void
        handleUpdateArticleDescription: (
            value: string,
            article: ArticleType,
        ) => void
        handleUpdateArticleQuantity: (
            value: number,
            article: ArticleType,
        ) => void
        renderErrorMessage: (error: string) => JSX.Element | null
        setIsRebate: (value: boolean) => void
        onPriceAdjustmentChanged: (value: string) => void
    }
    states: {
        partnerOwnedLicense: boolean
        partners: PartnerType[]
        selectedPartner: PartnerType | null
        usePartnerArticles: boolean
        articleList: ArticleType[] | null
        selectedArticles: ArticleSetType[]
        articleSetDescriptionErrors: { [key: string]: string }
        articleError: string
        selectedCustomer: CustomerType | null
        user: User | undefined
        isRebate: boolean
        priceAdjustmentPercentage: string | number
        priceAdjustmentWarning: string | null
        priceAdjustmentError: string
    }
}

export default function ArticlesTab(props: ArticlesTabProps) {
    const { handlers, states } = props

    const renderArticleRadioButtons = () => {
        if (states.partners.length === 0 || states.partnerOwnedLicense) {
            return null
        }

        return (
            <RadioButtons>
                <Checkbox
                    {...{
                        type: 'radio',
                        id: 'my-articles',
                        name: 'article-options',
                        label: 'My articles',
                        defaultChecked: !states.usePartnerArticles,
                        onChange: () => handlers.handleArticleRadioChange(),
                    }}
                />
                <Checkbox
                    {...{
                        type: 'radio',
                        id: 'partner-articles',
                        name: 'article-options',
                        label: 'Partners articles',
                        defaultChecked: states.usePartnerArticles,
                        onChange: () => handlers.handleArticleRadioChange(),
                    }}
                />
            </RadioButtons>
        )
    }

    const renderPartnerDropdown = () => {
        if (!states.usePartnerArticles) {
            return null
        }

        const options = states.partners.map((partner, index) => ({
            value: index,
            label: partner.partner_name,
        }))

        const value = states.selectedPartner
            ? {
                  value: states.partners.findIndex(
                      (partner) => partner.id === states.selectedPartner?.id,
                  ),
                  label: states.selectedPartner.partner_name,
              }
            : null

        return (
            <SelectDropdown
                options={options}
                value={value}
                onChange={(index: number) =>
                    handlers.handleSetSelectedPartner(index)
                }
                placeholder="Choose partner"
                isSearchable
                isDisabled={states.partners.length < 2}
            />
        )
    }

    const renderPartnerSelectSection = () => {
        if (states.partnerOwnedLicense) {
            return null
        }

        return (
            <>
                <FormLabel>Articles</FormLabel>
                <MultipleSection>
                    <div>{renderArticleRadioButtons()}</div>
                    <div style={{ marginBottom: '-5px' }}>
                        {renderPartnerDropdown()}
                    </div>
                </MultipleSection>
            </>
        )
    }

    const renderArticlesDropdownContainer = () => {
        if (states.partnerOwnedLicense) {
            return null
        }

        return (
            <ArticlesDropdownContainer
                selectedCustomer={states.selectedCustomer}
                selectedArticles={states.selectedArticles}
                articleList={states.articleList}
                usePartnerArticles={states.usePartnerArticles}
                selectedPartner={states.selectedPartner}
                handleAddArticle={handlers.handleAddArticle}
            />
        )
    }

    const renderArticleDescription = (article: ArticleSetType) => {
        return (
            <EditableTextButton
                updateValue={(value) =>
                    handlers.handleUpdateArticleDescription(
                        value,
                        article.article,
                    )
                }
                text={article.description?.toString()}
                placeholder="Add text to invoice"
            />
        )
    }

    const renderArticleListItem = (article: ArticleSetType) => (
        <LicenseListItem key={article.article.id}>
            <section id="info-section">
                <div id="section-one">
                    <span id="name">{article.article.name}</span>
                    {renderArticleDescription(article)}
                </div>
                <span id="code">{article.article.external_id}</span>
                <NumberInput
                    id="quantity"
                    value={article.quantity.toString()}
                    onChange={(e) =>
                        handlers.handleUpdateArticleQuantity(
                            Number(e),
                            article.article,
                        )
                    }
                    config={{
                        min: 0,
                        max: 999999,
                        allowEmpty: false,
                        allowLeadingZero: false,
                        allowDecimal: { isEnabled: false },
                    }}
                    incrementButton
                    decrementButton
                    disabled={states.partnerOwnedLicense}
                />
                <span id="price">{`${localeNumber(
                    Number(article.article.msrp),
                    {
                        locale: states.user?.locale,
                    },
                )} ${article.article.currency}`}</span>
            </section>
            {!states.partnerOwnedLicense && (
                <RemoveListItemButton
                    id="remove-article"
                    onClick={() =>
                        handlers.handleRemoveArticle(article.article)
                    }
                    className="fas fa-times fa-lg"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter')
                            handlers.handleRemoveArticle(article.article)
                    }}
                    role="button"
                    tabIndex={0}
                    aria-label="Remove article"
                />
            )}
        </LicenseListItem>
    )

    const renderSelectedArticles = () => (
        <ArticlesSection>
            <div className="column-header">
                <p id="article-header">Article</p>
                <p id="id-header">Id</p>
                <p id="quantity-header">Quantity</p>
                <p id="price-header">Price</p>
            </div>
            <ArticleList>
                {states.selectedArticles.map((article) => (
                    <React.Fragment key={article.article.id}>
                        <ArticleSetWrapper>
                            {renderArticleListItem(article)}
                        </ArticleSetWrapper>
                        {handlers.renderErrorMessage(
                            states.articleSetDescriptionErrors[
                                article.article.id
                            ],
                        )}
                    </React.Fragment>
                ))}
            </ArticleList>
        </ArticlesSection>
    )

    const renderSelectedArticlesSection = () => {
        const html =
            states.selectedArticles.length === 0 ? (
                <EmptyListIcon insideModal />
            ) : (
                renderSelectedArticles()
            )

        return (
            <>
                {html}
                {handlers.renderErrorMessage(states.articleError)}
            </>
        )
    }

    const renderArticleSection = () => {
        return (
            <>
                {renderArticlesDropdownContainer()}
                {renderSelectedArticlesSection()}
            </>
        )
    }

    const renderPriceAdjustmentRadioButtons = () => {
        return (
            <RadioButtons>
                <Checkbox
                    {...{
                        type: 'radio',
                        id: 'rebate',
                        name: 'rebate-markup-options',
                        label: 'Rebate',
                        defaultChecked: states.isRebate,
                        onChange: () => handlers.setIsRebate(true),
                        disabled: states.partnerOwnedLicense,
                    }}
                />
                <Checkbox
                    {...{
                        type: 'radio',
                        id: 'markup',
                        name: 'rebate-markup-options',
                        label: 'Markup',
                        defaultChecked: !states.isRebate,
                        onChange: () => handlers.setIsRebate(false),
                        disabled: states.partnerOwnedLicense,
                    }}
                />
            </RadioButtons>
        )
    }

    const renderPriceAdjustmentWarning = () => {
        if (!states.priceAdjustmentWarning || states.priceAdjustmentError) {
            return null
        }

        return <WarningMessage>{states.priceAdjustmentWarning}</WarningMessage>
    }

    const renderLicenseTotalPrice = () => {
        if (states.selectedArticles.length === 0) {
            return null
        }

        let totalPrice = states.selectedArticles.reduce(
            (totalValue, article) =>
                totalValue + Number(article.article.msrp) * article.quantity,
            0,
        )

        if (states.isRebate) {
            totalPrice *= 1 - Number(states.priceAdjustmentPercentage) / 100
        } else {
            totalPrice *= 1 + Number(states.priceAdjustmentPercentage) / 100
        }

        return (
            <TotalPriceSection>
                <p>Total:</p>
                <p>
                    {`${localeNumber(totalPrice, {
                        locale: states.user?.locale,
                    })} ${states.selectedArticles[0].article.currency}`}
                </p>
            </TotalPriceSection>
        )
    }

    const renderPriceAdjustment = () => {
        return (
            <PriceAdjustmentSection>
                <div id="price-adjustment">
                    {renderPriceAdjustmentRadioButtons()}
                    <NumberInput
                        id="price-adjustment-input"
                        placeholder="-"
                        value={states.priceAdjustmentPercentage.toString()}
                        onChange={handlers.onPriceAdjustmentChanged}
                        config={{
                            min: 0,
                            max: 9999,
                            allowLeadingZero: false,
                            allowDecimal: { maxDecimalPlaces: 3 },
                        }}
                        disabled={states.partnerOwnedLicense}
                    />
                    <span id="price-adjustment-info">%</span>
                </div>
                {renderLicenseTotalPrice()}
                {renderPriceAdjustmentWarning()}
                {handlers.renderErrorMessage(states.priceAdjustmentError)}
            </PriceAdjustmentSection>
        )
    }

    return (
        <>
            {renderPartnerSelectSection()}
            {renderArticleSection()}
            {renderPriceAdjustment()}
        </>
    )
}
