import React, { useState } from 'react'

import { SharlicLogo } from 'apps/shared/components/Logo'

import { RecoverFrame } from './Recover.styled'

export default function RecoverPage() {
    const [contact, setContact] = useState('')

    const handleSubmit = (e: any) => {
        e.preventDefault()

        alert('Not yet implemented!')
    }

    return (
        <RecoverFrame>
            <SharlicLogo />
            <div>
                <h2>Recover your password</h2>
                <p>
                    Enter the email (or mobile number) that you used when
                    registering to recover password. You will receive a password
                    reset link.
                </p>
            </div>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    id="username"
                    placeholder="Username"
                    onChange={(e) => setContact(e.target.value)}
                />
                <button type="submit">Send</button>
            </form>
        </RecoverFrame>
    )
}
