import styled from 'styled-components'

export const FooterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    > .info {
        font-size: 0.9rem;
        width: 70%;
        padding: 0.5rem 1.2rem;
        > button {
            padding-top: 0;
        }
    }
    > .buttons {
        display: flex;
        gap: 1rem;
        align-items: flex-end;
    }
`
