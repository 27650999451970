import React from 'react'

import { DashboardPage } from 'apps/customer/pages/Dashboard'
import { RouteType } from 'routes/utils'

const getPrivateRoutes = (): RouteType[] => {
    return [
        {
            path: '/',
            element: <DashboardPage />,
            permissions: ['customer'],
        },
    ]
}

export default getPrivateRoutes
