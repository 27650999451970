import React from 'react'
import { Accordion } from 'react-bootstrap'

type AccordionItemType = {
    eventKey: string
    header: JSX.Element | JSX.Element[]
    body: JSX.Element | JSX.Element[]
}

export default function AccordionItem(accordionObject: AccordionItemType) {
    const { eventKey, header, body } = accordionObject

    return (
        <Accordion.Item eventKey={eventKey}>
            <Accordion.Header>{header}</Accordion.Header>
            <Accordion.Body>{body}</Accordion.Body>
        </Accordion.Item>
    )
}
