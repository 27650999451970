import React from 'react'

import {
    DashboardCardWrapper,
    DashboardIcon,
    DashboardCardContent,
} from './DashboardCard.styled'

type DashboardCardProps = {
    image: string
    title: string
    value: string
    description: string
}

export default function DashBoardCard(defaultProps: DashboardCardProps) {
    const { image, title, value, description } = defaultProps

    return (
        <DashboardCardWrapper>
            <DashboardIcon className={image} />
            <DashboardCardContent>
                <p className="title">{title}</p>
                <h3 className="value">{value}</h3>
                <p className="description">{description}</p>
            </DashboardCardContent>
        </DashboardCardWrapper>
    )
}
