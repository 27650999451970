import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const DashboardCardWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 4fr;
    padding: 16px 10px 0 20px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    min-height: 130px;
    color: ${secondary.matteDeepBlue};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 768px) {
        height: 100px;
        div {
            .title {
                margin: 0;
            }
        }
    }
`

export const DashboardIcon = styled.i`
    font-size: 2.5em;
`

export const DashboardCardContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: right;
    max-height: 100%;
    overflow: hidden;

    > .title {
        font-size: 1.2rem;
        margin-bottom: 20px;
        color: ${primary.darkGray};
    }

    > .description {
        font-size: 0.8rem;
        color: ${primary.lightGray};
    }
    > .value {
        font-size: 2.2rem;
        font-weight: bold;
    }
`
