import {
    combineReducers,
    configureStore,
    PreloadedState,
} from '@reduxjs/toolkit'

import reducer from './reducer'
import user from './user'
import vendor from './vendor'

const rootReducer = combineReducers({
    user,
    vendor,
})

export function setupStore(preloadedState?: PreloadedState<RootState>) {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
    })
}

const store = configureStore({ reducer })
export type RootState = ReturnType<typeof store.getState>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch

export default store
