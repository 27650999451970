import { toast } from 'react-toastify'

import { ToastOptions } from './types/useToast.types'

const useToast = () => {
    const defaultToast = (message: string, props?: ToastOptions) => {
        if (!props) {
            toast(message)
        } else {
            const { autoclose } = props
            toast(message, { autoClose: autoclose || 5000 })
        }
    }

    const infoToast = (message: string, props?: ToastOptions) => {
        if (!props) {
            toast.info(message)
        } else {
            const { autoclose } = props
            toast.info(message, { autoClose: autoclose || 5000 })
        }
    }

    const successToast = (message: string, props?: ToastOptions) => {
        if (!props) {
            toast.success(message)
        } else {
            const { autoclose } = props
            toast.success(message, { autoClose: autoclose || 5000 })
        }
    }

    const errorToast = (message: string, props?: ToastOptions) => {
        if (!props) {
            toast.error(message)
        } else {
            const { autoclose } = props
            toast.error(message, { autoClose: autoclose || 5000 })
        }
    }

    const warningToast = (message: string, props?: ToastOptions) => {
        if (!props) {
            toast.warning(message)
        } else {
            const { autoclose } = props
            toast.warning(message, { autoClose: autoclose || 5000 })
        }
    }

    const promiseToast = (props: ToastOptions) => {
        const { autoclose, promise } = props

        if (!promise) {
            return
        }

        toast.promise(
            promise.promise,
            {
                pending: promise.state.pending,
                success: promise.state.success,
                error: promise.state.error,
            },
            { autoClose: autoclose || 5000 },
        )
    }

    return {
        defaultToast,
        infoToast,
        successToast,
        errorToast,
        warningToast,
        promiseToast,
    }
}

export default useToast
