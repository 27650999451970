import styled from 'styled-components'
import { primary } from 'utils/colors'

export const InfoIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5rem;
    color: white;
    background-color: ${primary.lightBlue};
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    margin: 1rem 0;
`
