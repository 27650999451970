import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'

import { SelectDropdown } from 'apps/vendor/components/Selects'
import { CustomerType } from 'apps/vendor/interfaces/subscriptions'
import { ArticleType } from 'apps/vendor/pages/Articles'

import { ArticleSetType } from '../Modals/LicenseModal'
import { PartnerType } from '../PartnerList'
import { ArticleCurrencyDropdownContainer } from './ArticlesDropdownContainer.styled'

type ArticlesDropdownProps = {
    selectedCustomer: CustomerType | null
    selectedArticles: ArticleSetType[]
    articleList: ArticleType[] | null
    selectedPartner: PartnerType | null
    usePartnerArticles: boolean
    handleAddArticle: (article: ArticleType) => void
}

export default function ArticlesDropdownContainer(
    defaultProps: ArticlesDropdownProps,
) {
    const {
        selectedCustomer,
        selectedArticles,
        articleList,
        selectedPartner,
        usePartnerArticles,
        handleAddArticle,
    } = defaultProps

    const [selectedCurrency, setSelectedCurrency] = useState<string | null>(
        null,
    )

    const filteredDropdownArticles =
        articleList?.filter(
            (article: { currency: string }) =>
                article.currency === selectedCurrency,
        ) || []

    const articlesAvailable = filteredDropdownArticles.length > 0
    const [className, setClassName] = useState('')

    useEffect(() => {
        if (
            (!usePartnerArticles || (usePartnerArticles && selectedPartner)) &&
            !articlesAvailable &&
            !selectedArticles.length
        ) {
            setClassName('missing-asset')
        } else {
            setClassName('')
        }
    }, [
        usePartnerArticles,
        selectedPartner,
        articlesAvailable,
        selectedArticles,
    ])

    useEffect(() => {
        if (selectedCustomer) {
            setSelectedCurrency(selectedCustomer.currency)
        }
    }, [selectedCustomer])

    const renderCurrencyField = () => {
        return (
            <Form.Control
                {...{
                    placeholder: 'Currency',
                    value: selectedCurrency || '',
                    type: 'text',
                    disabled: true,
                }}
            />
        )
    }

    const renderArticlesDropdown = () => {
        const options = filteredDropdownArticles.map((article) => ({
            value: article.id,
            label: article.name,
        }))

        let placeholder = articlesAvailable
            ? 'Add article'
            : 'No articles available'

        if (!articlesAvailable && selectedArticles.length > 0) {
            placeholder = 'No additional articles available'
        }

        if (usePartnerArticles && !selectedPartner) {
            placeholder = 'Select a partner'
        }

        return (
            <SelectDropdown
                options={options}
                onChange={(value) => {
                    const selectedArticle = articleList?.find(
                        (article) => article.id === value,
                    )

                    if (selectedArticle) {
                        handleAddArticle(selectedArticle)
                    }
                }}
                isDisabled={
                    !selectedCustomer ||
                    className !== '' ||
                    (usePartnerArticles && !selectedPartner) ||
                    !articlesAvailable
                }
                value={null}
                placeholder={placeholder}
                isSearchable
            />
        )
    }

    return (
        <ArticleCurrencyDropdownContainer>
            {renderCurrencyField()}
            {renderArticlesDropdown()}
        </ArticleCurrencyDropdownContainer>
    )
}
