import React from 'react'

import {
    CustomerSubscriptionDataType,
    LicenseStatus,
} from 'apps/vendor/interfaces/subscriptions'
import { formatDate, LicenseRevenueData } from 'apps/vendor/utils'
import useUser from 'hooks/useUser'
import useVendor from 'hooks/useVendor'

interface CustomerSubscriptionsInfoProps {
    customerData: CustomerSubscriptionDataType
}

export default function CustomerSubscriptionsInfo(
    defaultProps: CustomerSubscriptionsInfoProps,
) {
    const { customerData } = defaultProps
    const { vendor } = useVendor()
    const { user } = useUser()

    const activeLicenses =
        customerData.licenses.filter(
            (license) => LicenseStatus.ACTIVE === license.status,
        ) || []

    const [firstLicense] =
        activeLicenses.filter(
            (license) => license.status === LicenseStatus.ACTIVE,
        ) || []
    const [firstArticle] = firstLicense ? firstLicense.articles : []
    const selectedCurrency = firstArticle?.article.currency

    const licenses = LicenseRevenueData({
        licenses: activeLicenses,
        vendorId: vendor?.id || null,
        currency: selectedCurrency,
        locale: user?.locale,
    })
    if (!vendor) return null

    const estimatedYearlyRevenue = licenses?.totalRevenue || '-'

    const nextLicenseDate = licenses?.nextLicenseDate
        ? formatDate(new Date(licenses?.nextLicenseDate), user?.locale)
        : '-'

    return (
        <>
            <span>{customerData.name}</span>
            <span>{estimatedYearlyRevenue}</span>
            <span>{nextLicenseDate}</span>
            <span>-</span>
            <span>-</span>
        </>
    )
}
