import { StatusType } from 'apps/shared/components/Icons'

const badgeTypes: { [key in StatusType]?: string[] } = {
    [StatusType.SUCCESS]: ['PAID', 'PAYMENT SUCCEEDED'],
    [StatusType.WARNING]: ['CREATED', 'FINALIZED', 'UPCOMING', 'WILL BE DUE'],
    [StatusType.PRIMARY]: ['SENT'],
    [StatusType.DANGER]: [
        'OVERDUE',
        'DELETED',
        'FINALIZATION FAILED',
        'MARKED UNCOLLECTIBLE',
        'PAYMENT ACTION REQUIRED',
        'PAYMENT FAILED',
        'VOIDED',
    ],
}

export function getStatusType(status: string): StatusType | undefined {
    return (Object.keys(badgeTypes) as StatusType[]).find((type) =>
        badgeTypes[type]?.includes(status.toUpperCase()),
    )
}
