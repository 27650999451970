import React from 'react'

import BootstrapTable from 'apps/shared/components/BootstrapTable'
import { TableWrapper } from 'apps/vendor/components/Tables/Tables.styled'

import {
    InformationCardContent,
    InformationCardWrapper,
    InformationCardIcon,
    IconAndTitleWrapper,
} from '../InformationCard.styled'

interface ActionsNeededCardProps {
    value: string
    description: string
}

export default function ActionsNeededCard(
    defaultProps: ActionsNeededCardProps,
) {
    const { value, description } = defaultProps
    const accessors = ['description']

    const placeholderData = [
        {
            description: 'No actions needed.',
        },
    ]

    return (
        <InformationCardWrapper>
            <IconAndTitleWrapper>
                <InformationCardIcon className="fa-regular fa-person-digging" />
                <p className="title">Actions Needed</p>
            </IconAndTitleWrapper>
            <InformationCardContent>
                <TableWrapper numberOfColumns={1}>
                    <BootstrapTable
                        data={placeholderData}
                        accessors={accessors}
                    />
                </TableWrapper>
            </InformationCardContent>
        </InformationCardWrapper>
    )
}
