import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import useUser from 'hooks/useUser'
import LoadingStatus from 'state/enums/LoadingStatus'

export const waitStatuses = [LoadingStatus.IDLE, LoadingStatus.LOADING]

export default function ProtectedRoutes() {
    const { isLoggedIn, status } = useUser()

    if (waitStatuses.includes(status) || isLoggedIn) {
        return <Outlet />
    }

    return <Navigate to="/" replace />
}
