/* eslint-disable max-len */
export enum tokenFormats {
    UUID = '^[[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}]*$',
}

export enum whitelistURLParams {
    PARTNER_INVITATION = '^/partners/invitations/.*/accept',
    ARTICLES = '^articles$',
    PARTNERS = '^partners$',
    SUBSCRIPTIONS = '^subscriptions$',
}

export function paramInWhitelist(param: string) {
    const whitelist = Object.values(whitelistURLParams)

    const match = whitelist.filter((item) => {
        return param.match(item)
    })

    return match.length === 1
}
