import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { StripeLink } from 'api/vendors'
import useAppDispatch from 'hooks/useAppDispatch'
import { loadVendor } from 'state/vendor/actions'

export default function StripeLinkPage() {
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(true)
    const [linkUrl, setLinkUrl] = useState('')
    const location = useLocation()
    const check = new URLSearchParams(location.search).get('check')
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        StripeLink()
            .then((response) => {
                setLinkUrl(response.data.url)
                setSuccess(true)
                setLoading(false)
            })
            .catch(() => {
                setSuccess(false)
                setLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (success && !linkUrl) {
            dispatch(loadVendor())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success, linkUrl])

    if (linkUrl && !check) {
        window.location.href = linkUrl
    }

    if (loading) {
        return <h1>Loading..</h1>
    }

    if (success && !linkUrl) {
        setTimeout(() => {
            navigate('/')
        }, 5000)

        return (
            <div>
                <h1>Stripe account successfully linked!</h1>
                <p>Redirecting to home page..</p>
            </div>
        )
    }

    if (!loading && !linkUrl && !success) {
        return (
            <h1>
                Stripe account could not be linked, please contact sharlic for
                more help.
            </h1>
        )
    }

    return null
}
