import styled from 'styled-components'
import { primary } from 'utils/colors'

export const BooleanIconTrue = styled.i`
    padding: 2px;
    color: ${primary.green};
`

export const BooleanIconFalse = styled.i`
    padding: 2px;
    color: ${primary.red};
`
