import React, { useState, useEffect } from 'react'

import MultipleEmailInput from 'apps/shared/components/FormInputFields/EmailInput/MultipleEmailInput'
import { FormLabel, Switch } from 'apps/shared/shared.styled'
import { Checkbox } from 'apps/vendor/components/Modals/Modals.styled'

import {
    NotificationCheckboxes,
    NotificationSectionWrapper,
} from './NotificationSection.styled'

interface NotificationSettings {
    email: string
    delta: number
}

interface NotificationSectionProps {
    renewalNotifications: boolean
    setRenewalNotifications: (renewalNotifications: boolean) => void
    notificationSettings: NotificationSettings[]
    setNotificationSettings: (settings: NotificationSettings[]) => void
}

export default function NotificationSection({
    renewalNotifications,
    setRenewalNotifications,
    notificationSettings,
    setNotificationSettings,
}: NotificationSectionProps) {
    const getUnique = <T,>(arr: T[]): T[] => {
        return arr.filter((value, index, self) => self.indexOf(value) === index)
    }

    const [selectedEmails, setSelectedEmails] = useState<string[]>(
        getUnique(notificationSettings.map((setting) => setting.email)) || [],
    )

    const [selectedDays, setSelectedDays] = useState<number[]>(
        getUnique(notificationSettings.map((setting) => setting.delta) || []),
    )

    useEffect(() => {
        if (selectedEmails.length === 0) {
            setNotificationSettings([])

            return
        }

        const newSettings: NotificationSettings[] = []

        selectedEmails.forEach((email) => {
            selectedDays.forEach((delta) => {
                if (email.trim()) {
                    newSettings.push({ email, delta })
                }
            })
        })

        setNotificationSettings(newSettings)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEmails, selectedDays])

    const handleNotificationDays = (day: number) => {
        setSelectedDays((prevDays) => {
            if (prevDays.includes(day)) {
                return prevDays.filter((d) => d !== day)
            }

            return [...prevDays, day].sort((a, b) => a - b)
        })
    }

    const handleSetNotificationEmails = (emails: string[]) => {
        setSelectedEmails(emails.filter((email) => email.trim()))
    }

    const handleRenewalNotificationsToggle = () => {
        if (renewalNotifications) {
            setSelectedEmails([])
            setSelectedDays([])
            setNotificationSettings([])
        }
        setRenewalNotifications(!renewalNotifications)
    }

    const renderNotificationCheckbox = (label: string, value: number) => {
        return (
            <Checkbox
                {...{
                    id: `license-renewal-${value}-days`,
                    name: `license-renewal-${value}-days`,
                    label,
                    checked: selectedDays.includes(value),
                    onChange: () => handleNotificationDays(value),
                }}
            />
        )
    }

    const renderNotificationCheckboxes = () => {
        return (
            <NotificationCheckboxes>
                <div>
                    {renderNotificationCheckbox('7 days', 7)}
                    {renderNotificationCheckbox('14 days', 14)}
                    {renderNotificationCheckbox('30 days', 30)}
                    {renderNotificationCheckbox('60 days', 60)}
                    {renderNotificationCheckbox('90 days', 90)}
                    {renderNotificationCheckbox('On renewal day', 0)}
                </div>
            </NotificationCheckboxes>
        )
    }

    return (
        <>
            <Switch
                id="renewal-notification-toggle"
                label="Renewal notifications"
                checked={renewalNotifications}
                onChange={() => handleRenewalNotificationsToggle()}
            />
            {renewalNotifications && (
                <NotificationSectionWrapper>
                    {renderNotificationCheckboxes()}
                    <FormLabel>Notification emails</FormLabel>
                    <MultipleEmailInput
                        value={selectedEmails}
                        placeholder="Add email to receive notifications"
                        onChange={handleSetNotificationEmails}
                    />
                </NotificationSectionWrapper>
            )}
        </>
    )
}
