import { Alert, Form, Tabs } from 'react-bootstrap'

import styled from 'styled-components'
import { primary } from 'utils/colors'

export const ErrorMessage = styled.p`
    color: ${primary.red};
    font-style: italic;
    font-size: 0.85rem;
`

export const WarningMessage = styled.p`
    font-size: 0.85rem;
    font-style: italic;
`

export const ErrorContainer = styled.div`
    height: 0.85rem;
    max-height: 0.85rem;
    margin: 0;
`

export const FormLabel = styled(Form.Label)`
    font-size: 0.9rem;
    color: ${primary.darkBlue};

    > span {
        color: ${primary.darkGray};
    }
`

export const DoubleSection = styled.section`
    > div > :first-child {
        margin-bottom: 0.5rem;
    }

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    column-gap: 1rem;
    margin-bottom: 0;
`

export const Switch = styled(Form.Switch)`
    width: fit-content;
    cursor: pointer;

    input {
        height: 20px;
        cursor: pointer;
    }

    label {
        font-size: 0.9rem;
        cursor: pointer;
        margin-top: 3px;
    }

    .form-check-label {
        margin-bottom: 8px;
    }
`

export const TabWrapper = styled(Tabs)`
    padding-left: 10px;
    > * {
        font-size: 1.4rem;

        > .nav-link {
            color: ${primary.gray};
        }

        > .nav-link.active {
            color: ${primary.darkBlue};
        }
    }

    &.modal-header {
        border-bottom: none;
        margin-bottom: -16px;
        margin-left: -16px;
    }

    &.body {
        padding-left: 0;
        padding-bottom: 20px;
        border: none;
        display: flex;
        gap: 1rem;

        > * {
            font-size: 1rem;

            > .nav-link {
                border: none;
                color: ${primary.gray};

                &.nav-link.active {
                    color: ${primary.darkBlue};
                    border-color: ${primary.darkBlue};
                    border-bottom: 1px solid ${primary.lightGray};
                }
            }
        }
    }
`

export const AlertWrapper = styled(Alert)`
    display: flex;
    gap: 1rem;
`
