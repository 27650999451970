import React from 'react'

import { SharlicWhite } from 'assets'

export default function SharlicWhiteLogo() {
    return (
        <img
            src={SharlicWhite}
            id="sharlicWhiteLogo"
            alt="sharlic-white-logo"
        />
    )
}
