import React from 'react'

import { BooleanIconTrue, BooleanIconFalse } from './BooleanIcon.styled'

interface EmptyListProps {
    checked?: boolean
}

export default function BooleanIcon(defaultProps: EmptyListProps) {
    const { checked } = defaultProps

    if (checked) {
        return <BooleanIconTrue className="fa-solid fa-circle-check" />
    }

    return <BooleanIconFalse className="fa-solid fa-circle-xmark" />
}
