export const navItemList = [
    {
        text: 'Dashboard',
        icon: 'fa-regular fa-house',
        borderBottom: true,
        path: '/',
    },
    {
        text: 'Settings',
        icon: 'fa-regular fa-gear',
        path: '/settings',
        borderTop: true,
    },
]
