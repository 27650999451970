import React from 'react'

import { TextButtonWrapper } from './TextButton.styled'

export default function TextButton(props: {
    onClick: () => void
    text: string
}) {
    const { onClick, text } = props

    return (
        <TextButtonWrapper
            onClick={(e) => {
                onClick()
                e.stopPropagation()
            }}
        >
            {text}
        </TextButtonWrapper>
    )
}
