import React, { useState } from 'react'

import { deleteCustomer, getCustomers } from 'api/customers'
import AccordionItem from 'apps/shared/components/AccordionItem/AccordionItem'
import { KebabButton } from 'apps/shared/components/Buttons'
import TextButton from 'apps/shared/components/Buttons/TextButton'
import { EmptyListIcon } from 'apps/shared/components/Icons'
import { ConfirmModal } from 'apps/shared/components/Modals'
import { ConfirmModalBody } from 'apps/shared/components/Modals/ConfirmModal/ConfirmModal.styled'
import CustomerModal from 'apps/vendor/components/Modals/CustomerModal'
import { CustomerType } from 'apps/vendor/interfaces/subscriptions'
import { formatDate } from 'apps/vendor/utils'
import useToast from 'hooks/useToast'
import useUser from 'hooks/useUser'

import PageHeader from '../PageHeader'
import {
    AccordionBody,
    AccordionWrapper,
    BadgeRow,
    HeaderRow,
} from '../pages.styled'

export default function CustomersPage(props: {
    updateCustomers: (data: CustomerType[]) => void
    customers: CustomerType[]
}) {
    const { customers, updateCustomers } = props
    const [showCustomerModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [selectedCustomer, setSelectedCustomer] =
        useState<CustomerType | null>(null)

    const { user } = useUser()
    const { successToast, errorToast } = useToast()

    const updateCustomersList = () => {
        getCustomers().then((resp) => {
            updateCustomers(resp.data)
        })
    }

    const handleOpenEditModal = (customer: CustomerType) => {
        setSelectedCustomer(customer)
        setShowEditModal(!showCustomerModal)
    }

    const handleToggleDeleteModal = (customer: CustomerType) => {
        setSelectedCustomer(customer)
        setShowDeleteModal(!showDeleteModal)
    }

    const getCustomerBody = (customer: CustomerType) => {
        const dateString = formatDate(
            new Date(customer.created_at),
            user?.locale,
        )

        return (
            <AccordionBody>
                <div>
                    <p>Created</p>
                    <p>{dateString}</p>
                </div>
                <KebabButton
                    onEdit={() => handleOpenEditModal(customer)}
                    onDelete={() => handleToggleDeleteModal(customer)}
                />
            </AccordionBody>
        )
    }

    const onCustomerModalSubmit = () => {
        updateCustomersList()
        setSelectedCustomer(null)
        setShowEditModal(false)
    }

    const renderTable = () => {
        if (!customers) {
            return null
        }

        if (customers.length === 0) {
            return <EmptyListIcon />
        }

        return (
            <>
                <HeaderRow numberOfColumns={3}>
                    <span>Name</span>
                    <span>Reference</span>
                    <span>Email</span>
                </HeaderRow>
                <AccordionWrapper alwaysOpen>
                    {customers.map((customer, index) => {
                        return (
                            <AccordionItem
                                key={customer.id}
                                eventKey={index.toString()}
                                header={
                                    <BadgeRow numberOfColumns={3}>
                                        <TextButton
                                            onClick={() =>
                                                handleOpenEditModal(customer)
                                            }
                                            text={customer.name}
                                        />
                                        <span>{customer.reference || '-'}</span>
                                        <a
                                            href={`mailto:$
                                                {customer.contact_email}`}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            {customer.contact_email}
                                        </a>
                                    </BadgeRow>
                                }
                                body={getCustomerBody(customer)}
                            />
                        )
                    })}
                </AccordionWrapper>
            </>
        )
    }

    const handleDeleteCustomer = () => {
        if (selectedCustomer) {
            deleteCustomer(selectedCustomer.id)
                .then(() => {
                    setSelectedCustomer(null)
                    updateCustomersList()
                    setShowDeleteModal(!showDeleteModal)
                    successToast('Customer deleted successfully')
                })
                .catch(() => {
                    return errorToast(
                        // eslint-disable-next-line max-len
                        'Unknown error. Please try again and contact Sharlic support if error persists',
                    )
                })
        }
    }

    const renderDeletionConstraintsWarning = () => {
        return (
            <section>
                <p>
                    This customer cannot be deleted because it is currently in
                    use by the following licenses:
                </p>
                <ul>
                    {selectedCustomer?.related_licenses?.map((license) => (
                        <li key={license.name}>
                            <b>{license.name}</b>
                        </li>
                    ))}
                </ul>
            </section>
        )
    }

    const renderConfirmDeleteSection = () => {
        return (
            <>
                <section>
                    You are about to delete the following customer:
                    <p>
                        <b>{selectedCustomer?.name}</b>
                    </p>
                </section>
                <section>
                    <b>This action cannot be undone.</b>
                </section>
            </>
        )
    }

    const customerDeletable = () => {
        return selectedCustomer?.related_licenses?.length === 0
    }

    const renderConfirmModalBody = () => {
        let html = renderConfirmDeleteSection()

        if (!customerDeletable()) {
            html = renderDeletionConstraintsWarning()
        }

        return <ConfirmModalBody>{html}</ConfirmModalBody>
    }

    return (
        <div>
            <PageHeader
                onButtonClicked={() => setShowEditModal(!showCustomerModal)}
            />
            {showCustomerModal && (
                <CustomerModal
                    onClose={() => {
                        setShowEditModal(!showCustomerModal)
                        setSelectedCustomer(null)
                    }}
                    onSubmit={() => onCustomerModalSubmit()}
                    preselectedCustomer={selectedCustomer}
                    customers={customers}
                />
            )}
            {showDeleteModal && (
                <ConfirmModal
                    onClose={() => {
                        setShowDeleteModal(!showDeleteModal)
                        setSelectedCustomer(null)
                    }}
                    onSubmit={() => handleDeleteCustomer()}
                    customSubmitText="Delete"
                    body={renderConfirmModalBody()}
                    deleteButton
                    canSubmit={customerDeletable()}
                />
            )}
            {renderTable()}
        </div>
    )
}
