import React, { useEffect, useState } from 'react'

import { deletePartner, getPartners } from 'api/partners'
import AccordionItem from 'apps/shared/components/AccordionItem'
import { DeleteButton, KebabButton } from 'apps/shared/components/Buttons'
import { EmptyListIcon } from 'apps/shared/components/Icons'
import { ConfirmModal } from 'apps/shared/components/Modals'
import { ConfirmModalBody } from 'apps/shared/components/Modals/ConfirmModal/ConfirmModal.styled'
import PageHeader from 'apps/vendor/pages/PageHeader'
import {
    AccordionBody,
    AccordionWrapper,
    BadgeRow,
    HeaderRow,
} from 'apps/vendor/pages/pages.styled'
import { formatDate } from 'apps/vendor/utils'
import useToast from 'hooks/useToast'
import useUser from 'hooks/useUser'

export type PartnerType = {
    id: number
    partner_name: string
    contact_email: string
    created_at: string
    is_deletable?: boolean
    related_licenses?: { id: number; name: string; vendor: string }[]
}

interface partnerType {
    id: number
    name: string
    related_licenses?: { id: number; name: string; vendor: string }[]
}

export default function PartnerList(props: {
    updatePartners: (data: PartnerType[]) => void
    partners: PartnerType[]
}) {
    const { updatePartners, partners } = props

    const [selectedVendor, setSelectedVendor] = useState<partnerType | null>(
        null,
    )
    const { user } = useUser()

    const [partnerObjectsList, setPartnerObjectsList] = useState<
        PartnerType[] | null
    >(null)
    const { successToast, errorToast } = useToast()
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const updatePartnersList = () => {
        getPartners().then((response) => {
            updatePartners(response.data)
        })
        setSelectedVendor(null)
        setShowDeleteModal(false)
    }

    const handlePartnerDelete = () => {
        if (!selectedVendor) return

        deletePartner(selectedVendor.id)
            .then(() => {
                updatePartnersList()
                setSelectedVendor(null)
                setShowDeleteModal(!showDeleteModal)
                successToast('Partner deleted successfully')
            })
            .catch(() => {
                return errorToast(
                    // eslint-disable-next-line max-len
                    'Unknown error. Please try again and contact Sharlic support if error persists',
                )
            })
    }

    useEffect(() => {
        updatePartnersList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!partners) return

        setPartnerObjectsList([
            ...partners.map((partner) => {
                return {
                    ...partner,
                    buttons: (
                        <DeleteButton onClick={() => handlePartnerDelete()} />
                    ),
                }
            }),
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partners])

    const handleToggleDeleteModal = (partner: PartnerType) => {
        setSelectedVendor({
            id: partner.id,
            name: partner.partner_name,
            related_licenses: partner.related_licenses,
        })
        setShowDeleteModal(!showDeleteModal)
    }

    const getPartnerBody = (partner: PartnerType) => {
        const date = formatDate(new Date(partner.created_at), user?.locale)

        return (
            <AccordionBody>
                <div>
                    <p>Partner since</p>
                    <p>{date}</p>
                </div>
                <KebabButton
                    onDelete={() => handleToggleDeleteModal(partner)}
                />
            </AccordionBody>
        )
    }

    const renderTable = () => {
        if (!partnerObjectsList) return null

        if (partnerObjectsList.length === 0) {
            return <EmptyListIcon />
        }

        return (
            <>
                <HeaderRow numberOfColumns={2} style={{ marginTop: '1.5rem' }}>
                    <span>Name</span>
                    <span>Contact Email</span>
                </HeaderRow>

                <AccordionWrapper alwaysOpen>
                    {partnerObjectsList.map((partner, index) => {
                        return (
                            <AccordionItem
                                key={partner.partner_name}
                                eventKey={index.toString()}
                                header={
                                    <BadgeRow numberOfColumns={2}>
                                        <span>{partner.partner_name}</span>
                                        <span>{partner.contact_email}</span>
                                    </BadgeRow>
                                }
                                body={getPartnerBody(partner)}
                            />
                        )
                    })}
                </AccordionWrapper>
            </>
        )
    }

    const renderDeletionConstraintsWarning = () => {
        return (
            <section>
                <p>
                    This partner is still associated with the following licenses
                    and cannot be deleted.
                </p>
                <ul>
                    {selectedVendor?.related_licenses?.map((license) => (
                        <li key={license.name}>
                            <b>{license.name}</b> owned by{' '}
                            <b>{license.vendor}</b>
                        </li>
                    ))}
                </ul>
            </section>
        )
    }

    const renderConfirmDeleteSection = () => {
        return (
            <>
                <section>
                    You are about to delete the following partner:
                    <p>
                        <b>{selectedVendor?.name}</b>
                    </p>
                </section>
                <section>
                    <p>
                        You will no longer have access to any shared resources
                        from this partner.
                    </p>
                </section>
            </>
        )
    }

    const partnerDeletable = () => {
        return selectedVendor?.related_licenses?.length === 0
    }

    const renderConfirmModalBody = () => {
        let html = renderConfirmDeleteSection()

        if (!partnerDeletable()) {
            html = renderDeletionConstraintsWarning()
        }

        return <ConfirmModalBody>{html}</ConfirmModalBody>
    }

    return (
        <>
            <PageHeader />
            {showDeleteModal && (
                <ConfirmModal
                    onClose={() => {
                        setShowDeleteModal(!showDeleteModal)
                        setSelectedVendor(null)
                    }}
                    onSubmit={() => handlePartnerDelete()}
                    customSubmitText="Delete"
                    deleteButton
                    canSubmit={partnerDeletable()}
                    body={renderConfirmModalBody()}
                />
            )}
            {renderTable()}
        </>
    )
}
