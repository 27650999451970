import React from 'react'
import { Route, Routes, Outlet, Navigate } from 'react-router-dom'

import { LoginPage } from 'apps/shared/pages/Login'
import { NotFoundPage } from 'apps/shared/pages/NotFound'
import { PartnerAcceptPage } from 'apps/shared/pages/PartnerAccept'
import { RecoverPage } from 'apps/shared/pages/Recover'
import { RegisterPage } from 'apps/shared/pages/Register'
import { ResetPage } from 'apps/shared/pages/Reset'
import useUser from 'hooks/useUser'
import { CustomerRoutes, VendorRoutes } from 'routes'
import { NestedRouteType, RouteType } from 'routes/utils'
import hasPermission from 'state/user/userPermissions'
import { User } from 'state/user/UserState'

import ProtectedRoutes, { waitStatuses } from './ProtectedRoutes'

const NOT_FOUND = [{ path: '*', element: <NotFoundPage /> }]

const PUBLIC_ROUTES = [
    {
        path: '/recover',
        element: <RecoverPage />,
    },
    {
        path: '/reset',
        element: <ResetPage />,
    },
    {
        path: '/register/:invitationID',
        element: <RegisterPage />,
    },
    {
        // eslint-disable-next-line max-len
        path: '/partners/invitations/:invitationID/accept',
        element: <PartnerAcceptPage />,
    },
]

const LOGIN_ROUTE = [
    {
        path: '/',
        element: <LoginPage />,
    },
    {
        path: '/:?param?',
        element: <LoginPage />,
    },
    {
        path: '*',
        element: <Navigate to="/" />,
    },
]

export const buildRoutes = (routes: RouteType[], user: User | undefined) => {
    return routes
        .filter((route) => {
            if (!route?.permissions) {
                return true
            }

            return route.permissions.every((permission) =>
                hasPermission(user, permission),
            )
        })
        .map((route: RouteType) => (
            <Route path={route.path} element={route.element} key={route.path}>
                {route.nestedRoutes?.map((nestedRoute: NestedRouteType) => (
                    <Route
                        index={nestedRoute.index}
                        path={nestedRoute.path}
                        element={nestedRoute.element}
                        key={nestedRoute.path}
                    />
                ))}
            </Route>
        ))
}

const getPrivateRoutes = (user?: User): RouteType[] => {
    if (user?.user_type === 'vendor') {
        return VendorRoutes()
    }
    if (user?.user_type === 'customer') {
        return CustomerRoutes()
    }

    return []
}

function AppRoutes(): JSX.Element {
    const { user, isLoggedIn, status } = useUser()
    const privateRoutes = getPrivateRoutes(user)

    return (
        <Routes>
            {(!isLoggedIn && !waitStatuses.includes(status) && (
                <Route path="/" element={<Outlet />}>
                    {buildRoutes(PUBLIC_ROUTES, user)}
                    {buildRoutes(LOGIN_ROUTE, user)}
                </Route>
            )) || (
                <Route path="/" element={<Outlet />}>
                    {buildRoutes(PUBLIC_ROUTES, user)}
                    <Route element={<ProtectedRoutes />}>
                        {buildRoutes(privateRoutes, user)}
                    </Route>
                    {buildRoutes(NOT_FOUND, user)}
                </Route>
            )}
        </Routes>
    )
}

export default AppRoutes
