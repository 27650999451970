import React from 'react'
import { useNavigate } from 'react-router-dom'

import useEnforceAriaHiddenFalse from 'hooks/useEnforceAriaHiddenFalse'

export type navType = {
    text: string
    icon: string
    path: string
    borderTop?: boolean
    permissions?: string[]
}

export default function NavItem(navObject: navType) {
    const { text, icon, path } = navObject
    const navigate = useNavigate()
    const buttonRef = useEnforceAriaHiddenFalse<HTMLButtonElement>()

    return (
        <button
            ref={buttonRef}
            type="button"
            onClick={() => navigate(path)}
            key={path}
            className={`${icon} navButton`}
            data-path={path}
        >
            <span>{text}</span>
        </button>
    )
}
