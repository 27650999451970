import React from 'react'

import { CompanyInformation, UserInformation } from 'api/utils/interfaces'
import { TextInputField } from 'apps/shared/components/FormInputFields'

import { ConfirmationFormWrapper } from '../Register.styled'

type ConfirmationFormProps = {
    userInformation: UserInformation
    companyInformation: CompanyInformation
}

export default function ConfirmationForm(defaultProps: ConfirmationFormProps) {
    const { userInformation, companyInformation } = defaultProps

    const infoField = (label: string, value: string, id: string) => {
        return (
            <div>
                <h3>{label}</h3>
                <TextInputField value={value} id={id} disabled />
            </div>
        )
    }

    return (
        <ConfirmationFormWrapper>
            <h1>Review your information</h1>
            <form>
                <h2 className="personalInfo">Personal Information</h2>
                {infoField(
                    'Name',
                    // eslint-disable-next-line max-len
                    `${userInformation.first_name} ${userInformation.last_name}`,
                    'fname',
                )}
                {infoField('Email', userInformation.user_email, 'email')}
                {userInformation.user_phone &&
                    infoField(
                        'User phone',
                        `${userInformation.user_phone}`,
                        'phone',
                    )}
                <h2>Company Information</h2>
                {infoField(
                    'Company name',
                    companyInformation.company_name,
                    'company-name',
                )}
                {infoField(
                    'Organization number',
                    companyInformation.organization_number,
                    'email',
                )}
                {companyInformation.contact_email &&
                    infoField(
                        'Contact email',
                        companyInformation.contact_email,
                        'email',
                    )}
                {companyInformation.vendor_phone &&
                    infoField(
                        'Company phone',
                        `${companyInformation.vendor_phone}`,
                        'phone',
                    )}
                {companyInformation.invoice_email &&
                    infoField(
                        'Invoice email',
                        companyInformation.invoice_email,
                        'email',
                    )}
                {companyInformation.reference &&
                    infoField(
                        'Reference',
                        companyInformation.reference,
                        'reference-name',
                    )}
                {infoField(
                    'Country',
                    companyInformation.country.name,
                    'country',
                )}
                {infoField(
                    'Address field',
                    companyInformation.address_line_1,
                    'address-field-one',
                )}
                {companyInformation.address_line_2 &&
                    infoField(
                        'Address field two',
                        companyInformation.address_line_2,
                        'address-field-two',
                    )}
                {infoField('Zip code', companyInformation.zip_code, 'zip-code')}
                {infoField('City', companyInformation.city, 'city')}
                {companyInformation.state &&
                    infoField('State', companyInformation.state, 'email')}
            </form>
        </ConfirmationFormWrapper>
    )
}
