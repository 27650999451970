import React, { useEffect, useMemo, useState } from 'react'

import { UserInformation } from 'api/utils/interfaces'
import {
    TextInputField,
    PasswordInputField,
    PhoneInputField,
} from 'apps/shared/components/FormInputFields'
import {
    LabelAndInputContainer,
    RegisterForm,
    Space,
} from 'apps/shared/pages/Register/Register.styled'
import { DoubleSection, FormLabel } from 'apps/shared/shared.styled'
import isPasswordValid from 'apps/shared/utils/isPasswordValid'
import { CountryCode } from 'libphonenumber-js'

type UserInformationProps = {
    email: string
    onUserInformationChange: (
        userInformation: UserInformation,
        userInformationValid: boolean,
    ) => void
    userInformation: UserInformation
}

export default function UserInformationForm(
    defaultProps: UserInformationProps,
) {
    const { email, onUserInformationChange, userInformation } = defaultProps

    const [firstName, setFirstName] = useState(
        userInformation?.first_name || '',
    )

    const [lastName, setLastName] = useState(userInformation?.last_name || '')

    const [userPhone, setUserPhone] = useState(
        userInformation?.user_phone || '',
    )

    const [password, setPassword] = useState(userInformation?.password || '')

    const [passwordConfirmation, setPasswordConfirmation] = useState(
        userInformation?.password || '',
    )
    const [userInfoValid, setUserInfoValid] = useState(false)
    const [passwordError, setPasswordError] = useState('')
    const [phoneNumberError, setPhoneNumberError] = useState('')

    const [passwordConfirmationError, setPasswordConfirmationError] =
        useState('')

    useEffect(() => {
        setPasswordConfirmationError(
            password &&
                passwordConfirmation &&
                password !== passwordConfirmation
                ? 'Passwords do not match.'
                : '',
        )
    }, [password, passwordConfirmation])

    useEffect(() => {
        const userFormValid =
            firstName &&
            lastName &&
            password &&
            passwordConfirmation &&
            !passwordError &&
            !phoneNumberError &&
            !passwordConfirmationError

        setUserInfoValid(userFormValid || false)
    }, [
        firstName,
        lastName,
        password,
        passwordConfirmation,
        passwordConfirmationError,
        passwordError,
        phoneNumberError,
    ])

    const userInfo = useMemo(
        () => ({
            user_email: email,
            first_name: firstName,
            last_name: lastName,
            user_phone: userPhone,
            password,
        }),
        [email, firstName, lastName, userPhone, password],
    )

    useEffect(() => {
        onUserInformationChange(userInfo, userInfoValid)
    }, [onUserInformationChange, userInfo, userInfoValid])

    const renderPhoneSection = (
        phoneNumber: string,
        setPhoneNumber: (phone: string) => void,
        country?: CountryCode,
    ) => {
        return (
            <LabelAndInputContainer>
                <FormLabel>
                    Phone number <span>- Optional</span>
                </FormLabel>
                <PhoneInputField
                    {...{
                        phoneNumber,
                        onChange: (phoneInput, errorMessage) => {
                            setPhoneNumber(phoneInput)
                            setPhoneNumberError(errorMessage)
                        },
                        selectedCountry: country as CountryCode,
                    }}
                />
            </LabelAndInputContainer>
        )
    }

    const handleSetPassword = (newPassword: string) => {
        setPasswordError(
            newPassword === '' || isPasswordValid(newPassword)
                ? ''
                : `Password must contain at least one letter
            and one number and be at least 10 characters long.`,
        )
        if (passwordError || newPassword === '') {
            setPasswordConfirmation('')
        }
        setPassword(newPassword)
    }

    const renderLabelAndInput = (
        value: string,
        label: string,
        id: string,
        onChange?: (value: string) => void,
        autoFocus?: boolean,
    ) => {
        return (
            <LabelAndInputContainer>
                <FormLabel>{label}</FormLabel>
                <TextInputField
                    value={value}
                    id={id}
                    onChange={onChange}
                    required
                    disabled={value === email}
                    autoFocus={autoFocus}
                />
            </LabelAndInputContainer>
        )
    }

    const renderPasswordField = (
        value: string,
        onChange: any,
        label: string,
        error: string,
    ) => {
        return (
            <LabelAndInputContainer>
                <FormLabel>{label}</FormLabel>
                <PasswordInputField
                    value={value}
                    isInvalid={error !== ''}
                    onChange={onChange}
                    required
                    invalidFeedback={error}
                />
            </LabelAndInputContainer>
        )
    }

    const renderUserContactInformationForm = () => {
        return (
            <form>
                {renderLabelAndInput(email, 'Email', 'email')}
                <DoubleSection>
                    {renderLabelAndInput(
                        firstName,
                        'First name',
                        'first-name',
                        setFirstName,
                        true,
                    )}
                    {renderLabelAndInput(
                        lastName,
                        'Last name',
                        'last-name',
                        setLastName,
                    )}
                </DoubleSection>
                {renderPhoneSection(userPhone, setUserPhone)}
            </form>
        )
    }

    const renderPasswordSection = () => {
        return (
            <form>
                {renderPasswordField(
                    password,
                    handleSetPassword,
                    'Password',
                    passwordError,
                )}
                {password &&
                    isPasswordValid(password) &&
                    renderPasswordField(
                        passwordConfirmation,
                        setPasswordConfirmation,
                        'Confirm password',
                        passwordConfirmationError,
                    )}
                <Space />
            </form>
        )
    }

    return (
        <RegisterForm>
            <h1>User Information</h1>
            {renderUserContactInformationForm()}
            {renderPasswordSection()}
        </RegisterForm>
    )
}
