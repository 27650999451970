import React, { useEffect, useState } from 'react'
import { CountryData } from 'react-phone-input-2'

import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js'

import { PhoneInputWrapper } from './PhoneInputField.styled'

interface PhoneInputData {
    phoneNumber: string
    onChange: (number: string, errorMessage: string) => void
    selectedCountry?: CountryCode
    disabled?: boolean
}

export default function PhoneInputField(defaultProps: PhoneInputData) {
    const { phoneNumber, onChange, selectedCountry, disabled } = defaultProps

    const [phoneCountryCode, setPhoneCountryCode] = useState(
        selectedCountry || 'se',
    )
    const [dialCode, setDialCode] = useState('')
    const [phoneIsValid, setPhoneIsValid] = useState(true)
    const acceptedCountries = ['se', 'us']

    useEffect(() => {
        setPhoneCountryCode(selectedCountry || 'se')
    }, [selectedCountry])

    const handleOnChange = (value: string, countryData: CountryData) => {
        const { countryCode } = countryData
        const formatedPhoneNumber = `+${value}`

        const phoneNumberObject =
            parsePhoneNumberFromString(formatedPhoneNumber)

        const phoneIsOnlyCountryCode = value === countryData.dialCode
        let phoneError = ''

        if (phoneNumberObject) {
            phoneError = phoneNumberObject.isValid()
                ? ''
                : 'Invalid phone number.'
        } else {
            phoneError =
                phoneIsOnlyCountryCode || !value ? '' : 'Invalid phone number.'
        }

        setPhoneIsValid(!phoneError)

        if (acceptedCountries.includes(countryCode)) {
            if (countryCode !== phoneCountryCode) {
                setPhoneCountryCode(countryCode)
            }

            setDialCode(countryData.dialCode)

            const phoneNumberOrEmpty = phoneIsOnlyCountryCode
                ? ''
                : formatedPhoneNumber

            onChange(phoneNumberOrEmpty, phoneError)
        }
    }

    return (
        <div>
            <PhoneInputWrapper
                containerClass="phone-container"
                inputClass="phone-input"
                buttonClass="phone-button"
                dropdownClass="phone-dropdown"
                searchClass="phone-search"
                {...{
                    country: phoneCountryCode.toLocaleLowerCase(),
                    value: phoneNumber || dialCode,
                    onlyCountries: acceptedCountries,
                    countryCodeEditable: false,
                    onChange: (value, countryData: CountryData) =>
                        handleOnChange(value, countryData),
                    disabled,
                    isValid: phoneIsValid,
                }}
            />
        </div>
    )
}
