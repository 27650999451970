import { Form } from 'react-bootstrap'

import styled from 'styled-components'

export const PasswordInputWrapper = styled(Form.Group)`
    position: relative;
    margin: 0;

    .form-control {
        padding-right: 40px;
    }

    .no-invalid-icon.is-invalid {
        background-image: none !important;
    }

    .hide-show-pw {
        position: absolute;
        top: 42%;
        right: 10px;
        transform: translateY(-50%);
        background: none;
        border: none;
        height: 32px;
    }

    .invalid-feedback {
        position: absolute;
        left: 0;
        width: 100%;
    }
`
