import styled from 'styled-components'

export const ConfirmModalBody = styled.div`
    display: flex;
    flex-direction: column;

    .inner {
        margin-top: 1rem;
    }

    #table {
        width: 100%;
        margin-top: 0.5rem;
    }
`
