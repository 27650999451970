import React, { useEffect, useState } from 'react'

import { deactivateUser, getUsers } from 'api/users'
import AccordionItem from 'apps/shared/components/AccordionItem'
import { KebabButton } from 'apps/shared/components/Buttons'
import { BooleanIcon } from 'apps/shared/components/Icons'
import { ConfirmModal } from 'apps/shared/components/Modals'
import { ConfirmModalBody } from 'apps/shared/components/Modals/ConfirmModal/ConfirmModal.styled'
import { UserModal } from 'apps/vendor/components/Modals'
import ProfileModal from 'apps/vendor/components/Modals/ProfileModal'
import { formatDate } from 'apps/vendor/utils'
import useToast from 'hooks/useToast'
import useUser from 'hooks/useUser'
import { User } from 'state/user/UserState'

import PageHeader from '../PageHeader'
import {
    AccordionBody,
    AccordionWrapper,
    BadgeRow,
    HeaderRow,
} from '../pages.styled'

export default function Users() {
    const [users, setUsers] = useState<User[] | null>(null)
    const [userObjectsList, setUserObjectsList] = useState<User[] | null>(null)
    const [showUserModal, setShowUserModal] = useState(false)
    const [showDeactivateModal, setShowDeactivateModal] = useState(false)
    const [selectedUser, setSelectedUser] = useState<User | null>(null)
    const currentUser = useUser().user
    const { successToast, errorToast } = useToast()

    useEffect(() => {
        getUsers().then((response) => {
            setUsers(response.data)
        })
    }, [])

    useEffect(() => {
        if (!users) return

        setUserObjectsList([
            ...users.map((user) => {
                if (user.id === currentUser?.id) {
                    return {
                        ...user,
                        name: `${currentUser.first_name}
                            ${currentUser.last_name}`,
                    }
                }

                return {
                    ...user,
                    name:
                        (user.first_name || user.last_name) &&
                        `${user.first_name} ${user.last_name}`,
                }
            }),
        ])
    }, [users, currentUser])

    const handleShowUser = () => {
        setShowUserModal((current) => !current)
        setSelectedUser(null)
    }

    const updateUserList = () => {
        getUsers().then((response) => {
            setUsers(response.data)
        })
        setSelectedUser(null)
        setShowUserModal(false)
    }

    const handleEditUser = (user: User) => {
        setSelectedUser(user)
        setShowUserModal(!showUserModal)
    }

    const handleToggleDeactivateModal = (user: User) => {
        setSelectedUser(user)
        setShowDeactivateModal(!showDeactivateModal)
    }

    const renderKebabButton = (user: User) => {
        const isCurrentUser = user.id === currentUser?.id

        if (!user?.is_active) {
            return null
        }

        return (
            <KebabButton
                onEdit={user.is_active ? () => handleEditUser(user) : undefined}
                onDeactivate={
                    user.is_active && !isCurrentUser
                        ? () => handleToggleDeactivateModal(user)
                        : undefined
                }
            />
        )
    }

    const getUserBody = (user: User) => {
        const dateString = formatDate(
            new Date(user.date_joined),
            currentUser?.locale,
        )

        return (
            <AccordionBody>
                <div>
                    <p>Joined</p>
                    <p>{dateString}</p>
                </div>
                {renderKebabButton(user)}
            </AccordionBody>
        )
    }

    const renderTable = () => {
        if (!users || !userObjectsList) {
            return null
        }

        return (
            <>
                <HeaderRow numberOfColumns={5}>
                    <span>Name</span>
                    <span>Email</span>
                    <span>Phone Number</span>
                    <span className="centered">Admin</span>
                    <span className="centered">Active</span>
                </HeaderRow>
                <AccordionWrapper alwaysOpen>
                    {userObjectsList.map((user, index) => {
                        return (
                            <AccordionItem
                                key={user.id}
                                eventKey={index.toString()}
                                header={
                                    <BadgeRow numberOfColumns={5}>
                                        <span>{user.name || '-'}</span>
                                        <span>{user.email}</span>
                                        <span>{user.phone_number || '-'}</span>
                                        <span className="centered">
                                            <BooleanIcon
                                                checked={user.is_vendor_admin}
                                            />
                                        </span>
                                        <span className="centered">
                                            <BooleanIcon
                                                checked={user.is_active}
                                            />
                                        </span>
                                    </BadgeRow>
                                }
                                body={getUserBody(user)}
                            />
                        )
                    })}
                </AccordionWrapper>
            </>
        )
    }

    const handleDeactivateUser = () => {
        if (selectedUser) {
            deactivateUser(selectedUser.id)
                .then(() => {
                    updateUserList()
                    setSelectedUser(null)
                    setShowDeactivateModal(!showDeactivateModal)
                    successToast('User deactivated successfully')
                })
                .catch(() => {
                    return errorToast(
                        // eslint-disable-next-line max-len
                        'Unknown error. Please try again and contact Sharlic support if error persists',
                    )
                })
        }
    }

    const renderConfirmDeactivateModalBody = () => {
        return (
            <ConfirmModalBody>
                <>
                    <section>
                        You are about to deactivate the following user:
                        <p>
                            <b>{selectedUser?.name}</b>
                        </p>
                    </section>
                    <section>
                        <p>
                            This will revoke this users access henceforth. To
                            reactivate this user, please contact Sharlic for
                            support.
                        </p>
                    </section>
                </>
            </ConfirmModalBody>
        )
    }

    const displayUserOrProfileModal = () => {
        if (!showUserModal) return null

        const editingOwnUser = selectedUser?.id === currentUser?.id

        if (!editingOwnUser) {
            return (
                <UserModal
                    onClose={() => handleShowUser()}
                    onSubmit={() => updateUserList()}
                    preselectedUser={selectedUser}
                />
            )
        }

        return (
            <ProfileModal
                onClose={() => setShowUserModal(!showUserModal)}
                onSubmit={() => setShowUserModal(!showUserModal)}
            />
        )
    }

    return (
        <div>
            <PageHeader onButtonClicked={() => handleShowUser()} />
            {displayUserOrProfileModal()}
            {showDeactivateModal && (
                <ConfirmModal
                    onClose={() => {
                        setShowDeactivateModal(!showDeactivateModal)
                        setSelectedUser(null)
                    }}
                    onSubmit={() => handleDeactivateUser()}
                    customSubmitText="Deactivate"
                    body={renderConfirmDeactivateModalBody()}
                    deleteButton
                />
            )}
            {renderTable()}
        </div>
    )
}
