import React, { useState } from 'react'

import {
    EditableTextButtonInput,
    EditableTextButtonWrapper,
} from './EditableTextButton.styled'

export default function EditableTextButton(props: {
    updateValue: (value: string) => void
    text: string | undefined
    placeholder: string
}) {
    const { updateValue, text, placeholder } = props
    const [isEditing, setIsEditing] = useState(false)

    const handleBlur = (value: string) => {
        setIsEditing(false)
        updateValue(value)
    }

    if (isEditing) {
        return (
            <EditableTextButtonInput
                id="editable-text-input"
                type="text"
                value={text}
                placeholder="Enter text"
                onChange={(e: any) => updateValue(e.target.value)}
                onBlur={(e: any) => handleBlur(e.target.value.trim())}
                onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                        handleBlur(e.target.value.trim())
                    }
                }}
                autoFocus
            />
        )
    }

    return (
        <EditableTextButtonWrapper
            onClick={() => setIsEditing(true)}
            title={text || ''}
        >
            {text || placeholder}
        </EditableTextButtonWrapper>
    )
}
