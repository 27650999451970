import React from 'react'
import { Form } from 'react-bootstrap'

interface TextInputFieldProps {
    value: string
    onChange?: (value: string) => void
    placeholder?: string
    required?: boolean
    disabled?: boolean
    id?: string
    autoFocus?: boolean
    isInvalid?: boolean
    invalidFeedback?: string
}

export default function TextInputField(
    textInputFieldProps: TextInputFieldProps,
) {
    const {
        value,
        onChange,
        placeholder,
        required,
        disabled,
        autoFocus,
        isInvalid,
        invalidFeedback,
    } = textInputFieldProps

    if (value === null) {
        return null
    }

    return (
        <Form.Group controlId={placeholder}>
            <Form.Control
                {...{
                    id: placeholder,
                    placeholder,
                    value,
                    required,
                    disabled,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        onChange && onChange(e.target.value),
                    autoFocus,
                    isInvalid,
                }}
            />
            {isInvalid && invalidFeedback && (
                <Form.Control.Feedback type="invalid">
                    {invalidFeedback}
                </Form.Control.Feedback>
            )}
        </Form.Group>
    )
}
