export const navItemList = [
    {
        text: 'Dashboard',
        icon: 'fa-regular fa-house',
        path: '/',
    },
    {
        text: 'Subscriptions',
        icon: 'fa-regular fa-money-check-dollar-pen',
        path: '/subscriptions',
        borderTop: true,
    },
    {
        text: 'Administration',
        icon: 'fa-regular fa-cube',
        path: '/administration',
    },
    {
        text: 'Settings',
        icon: 'fa-regular fa-gear',
        path: '/settings',
        borderTop: true,
    },
    {
        text: 'Users',
        icon: 'fa-regular fa-users',
        path: '/users',
        permissions: ['is_vendor_admin'],
    },
]
