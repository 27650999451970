import React from 'react'
import { Form } from 'react-bootstrap'

import { FormLabel } from 'apps/shared/shared.styled'

import Modal from '../Modal'

export interface InputFormProps {
    title: string
    onClose: () => void
    footer: JSX.Element | JSX.Element[]
    formProps: FormProp[]
}

export interface FormProp {
    label: string
    form: {
        placeholder: string
        onChange: (value: any) => void
        type?: string
        autoFocus?: boolean
    }
}

export function InputFormBody(props: { formProps: FormProp[] }) {
    const { formProps } = props

    return (
        <div>
            {formProps &&
                formProps.map((item) => {
                    const { form, label } = item

                    return (
                        <div key={label}>
                            <FormLabel>{label}</FormLabel>
                            <Form.Control {...form} />
                        </div>
                    )
                })}
        </div>
    )
}

export default function InputFormModal(props: InputFormProps) {
    const { formProps } = props

    return <Modal {...props} body={<InputFormBody formProps={formProps} />} />
}
