type LocaleNumberOptions = {
    disableFractionDigits?: boolean
    locale?: string
}

export default function localeNumber(
    value: number,
    { disableFractionDigits = false, locale }: LocaleNumberOptions = {},
) {
    const formattedNumber = new Intl.NumberFormat(
        locale || navigator.language,
        {
            style: 'decimal',
            minimumFractionDigits: disableFractionDigits ? 0 : 2,
            maximumFractionDigits: disableFractionDigits ? 0 : 2,
        },
    ).format(value)

    return formattedNumber
}
