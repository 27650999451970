import React, { useState, useEffect, useRef } from 'react'
import { createRoot } from 'react-dom/client'
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    useMap,
    ZoomControl,
} from 'react-leaflet'

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

import { MapContainerWrapper, MapMarker, MarkerInfo } from './WorldMap.styled'

export type Coordinates = [number, number]
export type CustomerCoordinates = {
    coordinates: Coordinates
    name: string
}

interface WorldMapProps {
    coordinates?: Coordinates
    customerCoordinates?: CustomerCoordinates[]
    vendorAddress: {
        name: string
        address?: string
        city?: string
    }
}

const isValidCoordinate = (
    coord: Coordinates | undefined,
): coord is Coordinates => {
    return (
        Array.isArray(coord) &&
        coord.length === 2 &&
        coord[0] !== null &&
        coord[1] !== null
    )
}

function FitBounds({ bounds }: { bounds: L.LatLngBoundsExpression }) {
    const map = useMap()

    useEffect(() => {
        map.fitBounds(bounds)
    }, [map, bounds])

    return null
}

export default function WorldMap(defaultProps: WorldMapProps) {
    const { coordinates, vendorAddress, customerCoordinates } = defaultProps

    const baseMapUrl =
        // eslint-disable-next-line max-len
        'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'
    const zoom = 13
    const [customIcon, setCustomIcon] = useState<L.DivIcon | null>(null)
    const [customerIcons, setCustomerIcons] = useState<L.DivIcon[]>([])
    // const mapRef = useRef<L.Map | null>(null)

    useEffect(() => {
        const container = document.createElement('div')
        const root = createRoot(container)
        root.render(<MapMarker className="fa-solid fa-house" />)

        const icon = L.divIcon({
            className: 'Marker',
            html: container,
            iconAnchor: [7, 0],
        })

        setCustomIcon(icon)
    }, [])

    useEffect(() => {
        if (!customerCoordinates) return

        const createCustomIcon = () => {
            const container = document.createElement('div')
            const root = createRoot(container)

            root.render(<MapMarker className="fa-solid fa-map-pin customer" />)

            return L.divIcon({
                className: 'Marker',
                html: container,
                iconAnchor: [7, 0],
            })
        }

        const icons = customerCoordinates.map(() => createCustomIcon())
        setCustomerIcons(icons)
    }, [customerCoordinates])

    const renderPopup = (name: string, address?: string, city?: string) => {
        return (
            <Popup>
                <MarkerInfo>
                    <h3>{name}</h3>
                    <p>{address}</p>
                    <p>{city}</p>
                </MarkerInfo>
            </Popup>
        )
    }

    const renderMapContainer = () => {
        if (!customIcon) return null

        const validCoordinates = [
            ...(isValidCoordinate(coordinates) ? [coordinates] : []),
            ...(customerCoordinates
                ? customerCoordinates
                      .filter((customer) =>
                          isValidCoordinate(customer.coordinates),
                      )
                      .map((customer) => customer.coordinates)
                : []),
        ]

        const bounds =
            validCoordinates.length > 0
                ? L.latLngBounds(validCoordinates)
                : null

        return (
            <MapContainer
                center={coordinates || [55.598627, 13.003767]}
                zoom={zoom}
                style={{ height: '100%', width: '100%', borderRadius: '10px' }}
                worldCopyJump
                minZoom={2}
                zoomControl={false}
            >
                <TileLayer url={baseMapUrl} attribution="" />
                <ZoomControl position="topright" />
                {isValidCoordinate(coordinates) && (
                    <Marker position={coordinates} icon={customIcon}>
                        {renderPopup(
                            vendorAddress?.name,
                            vendorAddress?.address,
                            vendorAddress?.city,
                        )}
                    </Marker>
                )}
                {customerCoordinates &&
                    customerCoordinates
                        .filter((customer) =>
                            isValidCoordinate(customer.coordinates),
                        )
                        .map((customer, index) => (
                            <Marker
                                key={`${customer} - ${index + 1}`}
                                position={customer.coordinates}
                                icon={customerIcons[index]}
                            >
                                {renderPopup(customer.name)}
                            </Marker>
                        ))}
                {bounds && <FitBounds bounds={bounds} />}
            </MapContainer>
        )
    }

    return <MapContainerWrapper>{renderMapContainer()}</MapContainerWrapper>
}
