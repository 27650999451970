import styled from 'styled-components'
import { secondary } from 'utils/colors'

export const EmptyCardSpaceWrapper = styled.div`
    padding: 20px 10px 0 20px;
    width: 100%;
    background-color: none;
    width: calc((100% - 40px) / 3);
`

export const DashboardWrapper = styled.div`
    display: grid;
    grid-template-rows: 2fr 6fr;
    gap: 20px;
    padding: 1em 2em;
    height: auto;

    @media screen and (max-width: 768px) {
        height: 100vh;
        height: 100vh;
        grid-template-columns: 1fr;
    }
    @media screen and (min-width: 1600px) {
        grid-template-rows: 1fr 4fr;
    }
`

export const DashboardCardsRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    height: auto;
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`

export const InformationAndMapWrapper = styled.div`
    display: grid;
    grid-template-columns: calc((100% - 40px) / 3) auto;
    gap: 20px;
    height: auto;
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`

export const DashboardPageWrapper = styled.div`
    background-color: ${secondary.almostWhiteGray};
    margin: -1em -2em;
    min-height: 100vh;
`

export const InformationContainer = styled.div`
    display: grid;
    grid-template-rows: 2fr 1fr;
    gap: 20px;
    height: 100%;
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`
