import styled from 'styled-components'
import { primary } from 'utils/colors'

export const Delete = styled.i`
    padding: 2px;
    color: ${primary.red};

    :hover {
        cursor: pointer;
        color: ${primary.lightRed};
    }
    :active {
        color: ${primary.darkBlue};
    }
`
