import styled from 'styled-components'
import { primary } from 'utils/colors'

export const PageHeadWrapper = styled.header`
    text-align: right;
    width: 100%;
    height: 100%;
    position: relative;

    > button {
        background-color: ${primary.blue};
        margin-bottom: -45px;
        border: none;
    }

    > button:hover {
        background-color: ${primary.lightBlue};
    }

    > button:active {
        background-color: ${primary.darkBlue} !important;
    }
`
