import { useEffect, useRef, RefObject } from 'react'

const useEnforceAriaHiddenFalse = <T extends HTMLElement>(): RefObject<T> => {
    const elementRef = useRef<T>(null)

    useEffect(() => {
        const enforceAriaHiddenFalse = (element: HTMLElement) => {
            const observer = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (
                        mutation.type === 'attributes' &&
                        mutation.attributeName === 'aria-hidden'
                    ) {
                        if (element.getAttribute('aria-hidden') === 'true') {
                            element.setAttribute('aria-hidden', 'false')
                        }
                    }
                })
            })

            observer.observe(element, {
                attributes: true,
                attributeFilter: ['aria-hidden'],
            })

            // Initial enforcement
            element.setAttribute('aria-hidden', 'false')
        }

        if (elementRef.current) {
            enforceAriaHiddenFalse(elementRef.current)
        }
    }, [])

    return elementRef
}

export default useEnforceAriaHiddenFalse
