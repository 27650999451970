import React from 'react'
import { Form, FormLabel } from 'react-bootstrap'

import { MultipleSection } from 'apps/vendor/components/Modals/Modals.styled'
import { SelectDropdown } from 'apps/vendor/components/Selects'
import { LicenseStatus, TimePeriod } from 'apps/vendor/interfaces/subscriptions'

interface LicenseBillingSectionProps {
    preselectedValues: {
        licenseStatus: LicenseStatus
        paymentFrequency: TimePeriod
        startDate: string
        manualInvoicing: boolean
        invoiceStartDate?: string
    }
    partnerOwnedLicense: boolean
    onChangeStatus: (status: LicenseStatus) => void
    onChangePaymentFrequency: (frequency: number) => void
    onChangeStartDate: (startDate: string) => void
    onChangeInvoiceStartDate: (invoiceStartDate: string) => void
}

export default function LicenseBillingSection(
    defaultProps: LicenseBillingSectionProps,
) {
    const {
        preselectedValues,
        partnerOwnedLicense,
        onChangePaymentFrequency,
        onChangeStartDate,
        onChangeInvoiceStartDate,
    } = defaultProps

    const selectedPaymentFrequency =
        preselectedValues.paymentFrequency || TimePeriod.MONTHLY

    const selectedStartDate = preselectedValues.startDate

    const selectedInvoiceStartDate = preselectedValues
        ? preselectedValues.invoiceStartDate
        : selectedStartDate

    const handleSetStartDate = (date: string) => {
        if (selectedInvoiceStartDate && date > selectedInvoiceStartDate) {
            onChangeInvoiceStartDate(date)
        }
        onChangeStartDate(date)
    }

    const handleSetInvoiceStartDate = (date: string) => {
        if (!preselectedValues.manualInvoicing && date < selectedStartDate) {
            onChangeInvoiceStartDate(selectedStartDate)
        } else {
            onChangeInvoiceStartDate(date)
        }
    }

    const renderStartDate = () => (
        <>
            <FormLabel>Start date</FormLabel>
            <Form.Control
                type="date"
                value={selectedStartDate}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleSetStartDate(e.target.value)
                }
                data-testid="start-date-input"
                disabled={partnerOwnedLicense}
            />
        </>
    )

    const renderInvoiceStartDate = () => {
        return (
            <>
                <FormLabel>Billed by Sharlic from:</FormLabel>
                {preselectedValues.manualInvoicing ? (
                    <Form.Control type="text" value="-" disabled />
                ) : (
                    <Form.Control
                        type="date"
                        value={selectedInvoiceStartDate || selectedStartDate}
                        min={selectedStartDate}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleSetInvoiceStartDate(e.target.value)
                        }
                        data-testid="invoice-start-date-input"
                        disabled={partnerOwnedLicense}
                    />
                )}
            </>
        )
    }

    const renderStartDatesSection = () => {
        return (
            <MultipleSection>
                <div>{renderStartDate()}</div>
                <div>{renderInvoiceStartDate()}</div>
            </MultipleSection>
        )
    }

    const TimePeriodDisplay: { [key in TimePeriod]: string } = {
        [TimePeriod.MONTHLY]: 'Monthly',
        [TimePeriod.QUARTERLY]: 'Quarterly',
        [TimePeriod.ANNUALY]: 'Annually',
    }

    const renderPaymentFrequencyDropdown = () => {
        const options = Object.values(TimePeriod).map((period, index) => ({
            value: index,
            label: TimePeriodDisplay[period],
        }))

        const selectedOption = options.find(
            (option) =>
                option.label === TimePeriodDisplay[selectedPaymentFrequency],
        )

        const value = selectedOption
            ? {
                  value: selectedOption.value,
                  label: selectedOption.label.toString(),
              }
            : null

        return (
            <>
                <FormLabel>Payment frequency</FormLabel>
                <SelectDropdown
                    options={options}
                    value={value}
                    onChange={onChangePaymentFrequency}
                    isDisabled={partnerOwnedLicense}
                />
            </>
        )
    }

    return (
        <>
            {renderStartDatesSection()}
            {renderPaymentFrequencyDropdown()}
        </>
    )
}
