import React, { useEffect, useRef, useState } from 'react'

import { postInvitation } from 'api/invitations'
import { InputFormModal } from 'apps/shared/components/Modals'
import { CancelSubmitFooter } from 'apps/shared/components/Modals/Footers'
import useToast from 'hooks/useToast'
import isEmail from 'validator/lib/isEmail'

export default function NewInvitationModal(props: {
    onClose: () => void
    onSubmit: () => void
}) {
    const { onClose, onSubmit } = props
    const [invitationEmail, setInvitationEmail] = useState('')
    const { errorToast, successToast } = useToast()
    const [submitAttempted, setSubmitAttempted] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    const handleSetEmail = (email: string) => {
        setInvitationEmail(email)
        setSubmitAttempted(false)
    }

    const handleNewInvitation = () => {
        postInvitation({ email: invitationEmail })
            .then(() => {
                onSubmit()

                successToast(
                    `Invitation successfully sent to ${invitationEmail}.`,
                )
            })
            .catch((err) => {
                errorToast(
                    // eslint-disable-next-line max-len
                    `Invitation could not be sent to ${invitationEmail}. ${err.response.data.detail}`,
                )
                setSubmitAttempted(true)
            })
    }

    const emailField = {
        label: 'Email Address',
        form: {
            ref: inputRef,
            placeholder: 'name@example.com',
            onChange: (e: any) => handleSetEmail(e.target.value),
            type: 'email',
        },
    }

    return (
        <InputFormModal
            onClose={onClose}
            title="New"
            formProps={[emailField]}
            footer={
                <CancelSubmitFooter
                    onClose={onClose}
                    onSubmit={handleNewInvitation}
                    canSubmit={!submitAttempted && isEmail(invitationEmail)}
                />
            }
        />
    )
}
